import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AgentInfo } from '../types/agent-info';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private agentInfSubject = new Subject<AgentInfo>();;
  agentInfo$ = this.agentInfSubject.asObservable();

  constructor() { }

  setAgentInfo(agent: any) {
    this.agentInfSubject.next(new AgentInfo(agent));
  }
}