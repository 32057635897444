<div class="stepper-header flex flex-row justify-center">
    <button mat-button (click)="back()" color="accent" class="stepper-previous">
        <mat-icon>keyboard_arrow_left</mat-icon> Back
    </button>
    <div class="text-center">
        <h1 class="mat-h1 mb-0">Use of Proceeds</h1>
    </div>
</div>
<div class="flex flex-row justify-center grid grid-cols-12">
    <div
        class="flex flex-col lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
        <form [formGroup]="allocationFormGroup">
            <div class="mat-subtitle-2 text-grey">
                <p>
                    The Bolt loan proceeds are used for <strong>working capital</strong> and/or <strong> debt refinance</strong> only. Working capital does <strong>not</strong> include equipment purchases or inventory.
                </p>
                <p>
                    Misuse of SBA loan proceeds is considered loan fraud and may result in forced default and possible criminal penalties.
                </p>
            </div>
            <div class="sub-header">
                <h2 class="mat-h2 mb-0">What will the loan proceeds be used for? *</h2>
                <mat-label class="mat-subtitle-2 text-grey">Please identify how your loan proceeds will be allocated. The total must add up to your requested loan amount.</mat-label>
            </div>
            <div [formGroup]="allocationFormGroup">
                <ng-container formArrayName="useOfProceeds">
                    <ng-container *ngFor="let useOfProceed of useOfProceeds.controls; let i=index">
                        <div class="flex flex-col">
                            <div class="flex flex-row justify-between items-baseline">
                                <mat-label>{{ $any(useOfProceed).get('purpose')?.value }}</mat-label>
                                <app-form-input-field [control]="$any(useOfProceed).get('amount')" [isCurrency]="true" mask="separator.2">
                                    <span matTextPrefix>$&nbsp;</span>
                                </app-form-input-field>                                            
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-col">
                <div class="flex flex-row justify-between items-baseline">
                    <mat-label class="bold">Loan Amount Requested</mat-label>
                    <app-form-input-field class="mat-form-field-override" [control]="$any(allocationFormGroup).get('fundingNeeded')" [isCurrency]="true" mask="separator.2">
                        <span matTextPrefix>$&nbsp;</span>
                    </app-form-input-field>
                </div>
                <div class="flex flex-row justify-between items-baseline">
                    <mat-label class="bold">Total Remaining</mat-label>
                    <app-form-input-field class="mat-form-field-override" [control]="$any(allocationFormGroup).get('totalRemaining')" [isCurrency]="true" mask="separator.2" [allowNegativeNumbers]="true">
                        <span matTextPrefix>$&nbsp;</span>
                    </app-form-input-field>
                </div>
            </div>
            <section class="flex flex-col workingCapitalSection" *ngIf="showUseOfProceeds()" [@heightTransition]>
                <mat-divider></mat-divider>
                <div class="info-text">
                    <span class="mat-subtitle-2 text-grey">
                        The SBA requires lenders to identify how working capital will be utilized by the business. Please check all that apply.
                    </span>
                </div>
                <ng-container formArrayName="allocations">
                    <ng-container *ngFor="let allocation of allocations.controls; let i=index">
                        <mat-checkbox [formControl]="$any(allocation).get('checked')">
                            <mat-label>{{ $any(allocation).get('purpose')?.value }}</mat-label>
                        </mat-checkbox>
                    </ng-container>
                </ng-container>
                <mat-error *ngIf="!allocationFormGroup.get('allocations')?.valid && allocationFormGroup.get('allocations')?.touched" class="error-message">
                    Please choose atleast one.
                </mat-error>
            </section>
            <mat-divider></mat-divider>
            <mat-checkbox formControlName="iAttestThatTheFundsWillBeUtilizedSolelyForTheSpecifiedPurposesMentionedAbove"
                class="acceptTerms">
                <mat-label>
                    I attest that the funds will be utilized solely for the specified purposes mentioned above.
                </mat-label>
            </mat-checkbox>
            <div class="flex flex-col items-center">
                <span class="mat-caption flex encriptionMessage text-grey gap-1">
                    <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                    <span>Your information is secure with 256-bit encription</span>
                </span>
                <button mat-flat-button (click)="next()" color="accent" class="action-button"
                    [disabled]="allocationFormGroup.invalid">Continue</button>
            </div>
        </form>
    </div>
</div>