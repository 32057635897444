import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import { AppDialog } from 'src/app/core/components/dialog/dialog.component';
import { businessStructure } from 'src/app/core/constants/dropdown-values';
import { OwnersFormService } from 'src/app/core/services/OwnersForm.service';
import { application, applicationDeclined, applicationFailure, applicationSuccess, documents, documentsFailure, documentsSuccess, prequalification, prequalificationDeclined, prequalificationFailure, prequalificationSuccess } from 'src/app/store/actions/bayfirst.actions';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  documentsFormGroup = this._formBuilder.group({
    secondCtrl: ['',],
  });
  files: any[] = [];
  owners: any[] = [];
  showProgressBlock: boolean = false;
  showSuccessBlock: boolean = false;
  showDeclinedBlock: boolean = false;
  showFailureBlock: boolean = false;
  message: string;
  numberOfFiles: number;
  numberOfUniqueFiles: number;
  businessStructure: any[] = businessStructure;

  @Input('businessInformationFormGroup') businessInformationFormGroup: FormGroup;
  @Output('formSubmit') formSubmit = new EventEmitter<any>();

  prequalificationLoading: boolean;
  prequalificationSuccess: boolean;
  applicationLoading: boolean;
  documentsLoading: boolean;
  applicationSuccess: boolean;
  documentsSuccess: boolean;
  allowSupportingDocuments: boolean = true;
  destroyed$ = new Subject<boolean>();

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly _stepper: CdkStepper,
    private location: Location,
    private route: ActivatedRoute,
    private actions$: Actions,
    private ownersFormService: OwnersFormService
  ) {
  }

  ngOnInit(): void {
    if (this.ownersFormService) {
      this.ownersFormService.getOwnersFormGroup().valueChanges.subscribe(x => {
        this.owners = x.owners;
        this.numberOfFiles = 2;
        if (this.owners && this.owners.length > 0) {
          this.owners.forEach(x => {
            if (this.hasOwnershipForDocuments(x.ownershipPercentage)) {
              if (x.isOwnerBusinessOrIndividual == 'individual') {
                this.numberOfFiles = this.numberOfFiles + this.GetNumberOfFiles(this.businessInformationFormGroup.get('businessStructure')?.value, this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.value);
              }
              else {
                this.numberOfFiles = this.numberOfFiles + 2;
                if (x.owners && x.owners.length > 0) {
                  x.owners.forEach((y: any) => {
                    if (this.hasOwnershipForDocuments(y.ownershipPercentage)) {
                      if (y.isOwnerBusinessOrIndividual == 'individual') {
                        this.numberOfFiles = this.numberOfFiles + this.GetNumberOfFiles(x.businessStructure, x.doesYourBusinessFileTaxesOnAScheduleC);
                      }
                    }
                  });
                }
              }
            }
          });
        }
      });
    }
    this.actions$.pipe(
      ofType(
        prequalification,
        prequalificationSuccess,
        prequalificationDeclined,
        prequalificationFailure,
        application,
        applicationSuccess,
        applicationDeclined,
        applicationFailure,
        documents,
        documentsSuccess,
        documentsFailure
      ),
      takeUntil(this.destroyed$)
    ).subscribe((action: any) => {
      switch (action.type) {
        case prequalification.type:
          this.prequalificationLoading = true;
          break;
        case prequalificationSuccess.type:
          this.prequalificationLoading = false;
          this.prequalificationSuccess = true;
          break;
        case prequalificationDeclined.type:
          this.message = action.message;
          this.prequalificationLoading = false;
          this.showDeclinedBlock = true;
          break;
        case prequalificationFailure.type:
          this.message = action.error;
          this.showFailureBlock = true;
          break;
        case application.type:
          this.applicationLoading = true;
          break;
        case applicationSuccess.type:
          this.message = action.payload.message;
          this.applicationLoading = false;
          this.applicationSuccess = true;
          break;
        case applicationDeclined.type:
          this.message = action.message;
          this.applicationLoading = false;
          this.showDeclinedBlock = true;
          break;
        case applicationFailure.type:
          this.message = action.error;
          this.showFailureBlock = true;
          break;
        case documents.type:
          this.documentsLoading = true;
          break;
        case documentsSuccess.type:
          this.documentsLoading = false;
          this.documentsSuccess = true;
          this.showSuccessBlock = true;
          break;
        case documentsFailure.type:
          this.message = action.error;
          this.showFailureBlock = true;
          break;
      }
    });
  }

  GetNumberOfFiles(businessStructure: any, doesYourBusinessFileTaxesOnAScheduleC: any): number {
    const businessStructureSelected = this.businessStructure.filter(w => w.value == businessStructure)[0];
    if (businessStructureSelected.isScheduleCRequired && doesYourBusinessFileTaxesOnAScheduleC == 'yes') {
      return 1;
    }
    return 2;
  }

  back() {
    this._stepper.previous();
    this.location.go("bolt/allocation", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppDialog, {
      panelClass: 'modal-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showProgressBlock = true;
        this.formSubmit.emit();
      }
    });
  }

  fileUploadHandler($event: any[], isBusinessDocument: boolean, isEntity: boolean, taxIdNumber: string, documentType: string) {
    this.files = this.files.filter(x => !(x.isBusinessDocument == isBusinessDocument && x.taxIdNumber == taxIdNumber && x.isEntity == isEntity && x.documentType == documentType));
    $event.forEach(file => {
      this.files.push({
        "brokerIdentifier": "",
        "applicationReferenceId": "",
        "ownerReferenceId": "",
        "documentType": documentType,
        "fileName": file.name,
        "fileData": file.result,
        "isBusinessDocument": isBusinessDocument,
        "taxIdNumber": taxIdNumber,
        "isEntity": isEntity
      });
    });
    const groups = ['isBusinessDocument', 'taxIdNumber','isEntity','documentType'];
    this.numberOfUniqueFiles = Object.values(this.files.filter(x => x.documentType != 'bank_internal_documents').reduce((r, o) => {
        const key = groups.map(k => o[k]).join('|');
        (r[key] ??= []).push(o);
        return r;
    }, {})).length;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  displayBusinessStructureRestricted(businessStructure: string, doesYourBusinessFileTaxesOnAScheduleC: string): boolean {
    if (this.businessStructure?.length > 0) {
      const businessStructureSelected = this.businessStructure.filter(x => x.value == businessStructure)[0];
      if (businessStructureSelected && !businessStructureSelected.isScheduleCRequired) {
        return true;
      }
    }
    return doesYourBusinessFileTaxesOnAScheduleC == "no";
  }

  hasOwnershipForDocuments(ownershipPercentage: string): boolean {
    return Number(ownershipPercentage) >= 20;
  }
}
