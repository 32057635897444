import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CoreComponentsModule } from 'src/app/core/core-components.module';
import { BayFirstEffects } from 'src/app/store/effects/bayfirst.effects';
import { BayFirstReducer } from 'src/app/store/reducers/bayfirst.reducer';
import { AllocationComponent } from '../allocation/allocation.component';
import { BusinessInformationFormComponent } from '../business-information-form/business-information-form.component';
import { DocumentsComponent } from '../documents/documents.component';
import { GeneralInformationFormComponent } from '../general-information-form/general-information-form.component';
import { DetailedInfoFormComponent } from '../owner-information-form/detailed-info-form/detailed-info-form.component';
import { OwnerInformationFormComponent } from '../owner-information-form/owner-information-form.component';
import { OwnersComponent } from '../owner-information-form/owners/owners.component';
import { StepperFormComponent } from './stepper-form.component';

@NgModule({
  declarations: [
    StepperFormComponent,
    GeneralInformationFormComponent,
    BusinessInformationFormComponent,
    OwnerInformationFormComponent,
    DetailedInfoFormComponent,
    OwnersComponent,
    AllocationComponent,
    DocumentsComponent
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressBarModule,
    CoreComponentsModule,
    RxReactiveFormsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature('bayfirst', BayFirstReducer),
    EffectsModule.forFeature([BayFirstEffects])
  ],
  exports: [StepperFormComponent]
})
export class StepperFormModule { }
