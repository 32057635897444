import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'file-upload-dialog-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialog {
  constructor(public dialogRef: MatDialogRef<FileUploadDialog>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
