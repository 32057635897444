<mat-toolbar>
  <mat-toolbar-row *ngIf="environment.showToolbar" class="text-environment text-environment-{{environment.name}}">
    <span>{{environment.name}}</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <img src="../assets/svg/logo.svg" alt="BayFirst" />
    <span class="grow"></span>
    <button *ngIf="agentInfo && agentInfo.id > 0" mat-button [matMenuTriggerFor]="beforeMenu" class="call menu-button">
      <img src="../assets/svg/call.svg" alt="call" /> <span>Contact</span>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before" class="your-menu-class">
      <div class="p-1">
        <div class="with-icon mb-0-3">
          <mat-icon color="accent">home</mat-icon> <span class="mt-0-3 ml-0-3 capitalize">{{ agentInfo.brokerName }}</span>
        </div>      
        <div class="with-icon mb-0-3">
          <mat-icon color="accent">person</mat-icon> <span class="mt-0-3 ml-0-3 capitalize">{{ agentInfo.getAgentFullName() }}</span>
        </div>      
        <div *ngIf="agentInfo.phoneNumber" class="with-icon mb-0-3">
          <mat-icon color="accent">phone</mat-icon> <span class="mt-0-3 ml-0-3">{{ agentInfo.getPhoneNumber() }}</span>
        </div>      
        <div *ngIf="agentInfo.emailAddress" class="with-icon mb-0-3">
          <mat-icon color="accent" fontIcon="mail"></mat-icon> <span class="mt-0-3 ml-0-3">{{ agentInfo.emailAddress }}</span>
        </div>      
      </div>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<div class="content flex flex-row justify-center">
  <div class="container">
    <router-outlet></router-outlet>
    <div *ngIf="loading$ | async" class="flex flex-row justify-center loading-block">
      <div class="flex flex-col justify-center">
        <mat-spinner class="search-spinner" diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>
<footer>
  © {{currentYear}} BayFirst National Bank, Member FDIC, Equal Housing Lender
</footer>