<h1 mat-dialog-title>Select {{data.FullName | titlecase}} Spouse</h1>
<div mat-dialog-content>
  <div *ngFor="let ownershipIndividual of data.OwnershipIndividuals">
    <div class="mt-0-5">
      <button mat-flat-button color="primary" (click)="onSelect(ownershipIndividual)">
        {{ownershipIndividual.fullName | titlecase}}
      </button>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="onNoClick()">Cancel</button>
</div>