import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BayFirstService {

  constructor(public http: HttpClient) {
  }

  naicsCodes(params: string | null): Observable<any> {
    return this.http.get(environment.bayfirst.baseUrl + '/naics-codes/' + params, {
      headers: environment.bayfirst.headers
    })
  }

  prequalification(body: object): Observable<any> {
    return this.http.post(environment.bayfirst.baseUrl + '/prequalification', body, {
      headers: environment.bayfirst.headers
    })
  }

  application(body: object): Observable<any> {
    return this.http.post(environment.bayfirst.baseUrl + '/application', body, {
      headers: environment.bayfirst.headers
    })
  }

  document(body: object): Observable<any> {
    return this.http.post(environment.bayfirst.baseUrl + '/document', body, {
      headers: environment.bayfirst.headers
    })
  }

  broker(params: string | null): Observable<any> {
    return this.http.get(environment.bayfirst.baseUrl + '/broker/' + params, {
      headers: environment.bayfirst.headers
    })
  }
}
