<h1 mat-dialog-title>Address Validation</h1>
<div mat-dialog-content>
  <div class="margin-bottom">
    <table class="table">
      <tr>
        <td>
          <div class="sub-title">
            Entered Address:
          </div>
          <div>{{ addresses.address.streetAddress }}</div>
          <div>{{ addresses.address.city }}</div>
          <div>{{ addresses.address.state }}</div>
          <div>{{ addresses.address.ZIPCode }}</div>
        </td>
        <td>
          <div class="sub-title">
            Suggested Address:
          </div>
          <div *ngIf="!addresses.suggestedAddress">
            <div>We could not validate the entered address.</div>
          </div>
          <div *ngIf="addresses.suggestedAddress">
            <div>{{ addresses.suggestedAddress.streetAddress }}</div>
            <div>{{ addresses.suggestedAddress.city }}</div>
            <div>{{ addresses.suggestedAddress.state }}</div>
            <div>{{ addresses.suggestedAddress.ZIPCode }}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="pt-2">
          <button mat-flat-button color="accent" [mat-dialog-close]="addresses.address" cdkFocusInitial>Select entered address</button>
        </td>
        <td class="pt-2">
          <button *ngIf="!addresses.suggestedAddress" mat-flat-button color="accent" (click)="onNoClick()">Re-enter the address</button>          
          <button *ngIf="addresses.suggestedAddress" mat-flat-button color="accent" [mat-dialog-close]="addresses.suggestedAddress" cdkFocusInitial>Select suggested address</button>
        </td>
      </tr>
    </table>
  </div>
</div>