import { CdkStepper } from '@angular/cdk/stepper';
import { Location, ViewportScroller } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { heightTransition, horizontalStepTransition, overlayTransition } from 'src/app/core/animations/horizontal-step-transition';
import { businessStructure } from 'src/app/core/constants/dropdown-values';
import { OwnersFormService } from 'src/app/core/services/OwnersForm.service';
import { ViewChildrenManagerService } from 'src/app/core/services/ViewChildrenManager.service';
import { DetailedInfoFormComponent } from './detailed-info-form/detailed-info-form.component';

@Component({
  selector: 'app-owner-information-form',
  templateUrl: './owner-information-form.component.html',
  styleUrls: ['./owner-information-form.component.scss'],
  animations: [
    horizontalStepTransition('horizontalStepTransitionMain', '-100%'),
    horizontalStepTransition('horizontalStepTransitionChild', '100%'),
    overlayTransition('overlayTransition'),
    heightTransition()
  ]
})

export class OwnerInformationFormComponent implements AfterViewInit, OnInit {
  @Input('businessInformationFormGroup') businessInformationFormGroup: FormGroup;
  @ViewChildren(DetailedInfoFormComponent) detailedInfoFormComponents!: QueryList<DetailedInfoFormComponent>;

  ownersFormGroup: FormGroup;
  showRemoveOwnerConfirmationIndex: number | undefined;
  showDetailedInfoForm: boolean = false;
  disabledBusinessEntity: boolean = false;
  maxOwnersAllowed: number = 5;
  businessStructure: any[] = businessStructure;

  constructor(
    private scroll: ViewportScroller,
    public readonly _stepper: CdkStepper,
    private location: Location,
    private route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    private viewChildrenManager: ViewChildrenManagerService,
    private ownersFormService: OwnersFormService
  ) { }

  back() {
    this._stepper.previous();
    this.location.go("bolt/business", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  addDetailedOwnerInfo() {
    this.showDetailedInfoForm = true;
    this.scroll.scrollToPosition([0, 0]);
    this.ownersFormService.sortOwners(this.ownersFormGroup);
  }

  handleDetailedInfoToggle() {
    this.showDetailedInfoForm = !this.showDetailedInfoForm;
    this.ownersFormService.handleDetailedInfoToggle(this.owners);
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.businessInformationFormGroup.controls['businessStructure'].valueChanges.subscribe(x => {
      this.setOwnershipDefaults();
    });
    this.businessInformationFormGroup.controls['doesYourBusinessFileTaxesOnAScheduleC'].valueChanges.subscribe(x => {
      this.setOwnershipDefaults();
    });
    this.ownersFormGroup = this.ownersFormService.createForm();
  }

  ngAfterViewInit() {
    this.viewChildrenManager.setViewChildren(this.detailedInfoFormComponents);
  }
  
  get owners(): FormArray {
    return this.ownersFormGroup.get('owners') as FormArray;
  }

  setOwnershipDefaults() {
    const businessStructureSelected = this.businessStructure.filter(x => x.value == this.businessInformationFormGroup.get('businessStructure')?.value)[0];
    if (businessStructureSelected.isScheduleCRequired && this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.value == 'yes') {
      this.disabledBusinessEntity = true;
      this.maxOwnersAllowed = 1;
      for (let i = 0; i < this.owners.length; i++) {
        this.ownersFormService.removeOwner(this.ownersFormGroup, i);
      }
      this.owners.controls.forEach((x:any) => {
        x.get('isOwnerBusinessOrIndividual')?.setValue("individual");
        x.get('isPrimaryOwner')?.setValue(true);
        x.get('legalBusinessName')?.setValue("");
        x.get('fname')?.setValue("");
        x.get('lname')?.setValue("");
        x.get('ownershipPercentage')?.setValue("100");
        x.get('realOwnershipPercentage')?.setValue("100");
      });
    }
    else {
      this.disabledBusinessEntity = false;
      this.maxOwnersAllowed = 5;
    }
  }
}