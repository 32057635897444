export class AgentInfo {
  id: number;
  status: string | null;
  code: string | null;
  brokerName: string | null = "";
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  loanSystemDestinationId: number | null;
  useOfProceeds: AgentUseOfProceeds[];

  constructor(agent: any, productTypeName: string) {
    this.id = agent.id;
    this.status = agent.status;
    this.code = agent.code;
    this.brokerName = agent.brokerName;
    this.firstName = agent.firstName;
    this.middleName = agent.middleName;
    this.lastName = agent.lastName;
    this.emailAddress = agent.emailAddress;
    this.phoneNumber = agent.phoneNumber;
    this.loanSystemDestinationId = agent.loanSystemDestinationId;
    this.useOfProceeds = [];
    if (agent.useOfProceeds) {
      this.useOfProceeds = agent.useOfProceeds.filter((x:any) => x.productName === productTypeName);
    }
  }

  getAgentFullName(): string {
    const names = []
    if (this.firstName) {
      names.push(this.firstName.trim())
    }
    if (this.middleName) {
      names.push(this.middleName.trim())
    }
    if (this.lastName) {
      names.push(this.lastName.trim())
    }
    return names.length > 0 ? names.join(' ').trim() : ''
  }

  getPhoneNumber(): string {
    if (this.phoneNumber) {
      return this.phoneNumber.replace('-', '').replace(' ', '');
    }
    return ""
  }
}

export class AgentUseOfProceeds {
  productName: string | null;
  useOfProceedName: string | null;
  displayOrder: number;
  useOfProceeds: string[] | null;
}