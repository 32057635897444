import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { heightTransition } from 'src/app/core/animations/horizontal-step-transition';
import { ApplicationService } from 'src/app/core/services/application.service';
import { FormErrorService } from 'src/app/core/services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss'],
  animations: [
    heightTransition()
  ]
})
export class AllocationComponent implements OnInit {
   @Input('generalInformationFormGroup') generalInformationFormGroup: FormGroup;

  allocationFormGroup = this._formBuilder.group({
    iAttestThatTheFundsWillBeUtilizedSolelyForTheSpecifiedPurposesMentionedAbove: [false, Validators.requiredTrue],
    useOfProceeds: this._formBuilder.array(this.getUseOfProceeds()),
    allocations: this._formBuilder.array(this.getUseOfProceedItems()),
    fundingNeeded: [{ value: 0, disabled: true }],
    totalRemaining: [{ value: 0, disabled: true }, [Validators.min(0)]]
  }, { validators: requireCheckboxesToBeCheckedValidator() });

  get useOfProceeds(): FormArray {
    return this.allocationFormGroup.get('useOfProceeds') as FormArray;
  }

  get allocations(): FormArray {
    return this.allocationFormGroup.get('allocations') as FormArray;
  }

  constructor(private _formBuilder: FormBuilder, private readonly _stepper: CdkStepper, private location: Location, private route: ActivatedRoute, private applicationService: ApplicationService, public formErrorService: FormErrorService) {
  }

  ngOnInit(): void {
    this.generalInformationFormGroup.controls['fundingNeeded'].valueChanges.subscribe(fundingNeeded => {
      this.allocationFormGroup.controls.fundingNeeded.setValue(fundingNeeded, { onlySelf: true, emitEvent: true });
      this.allocationFormGroup.controls.fundingNeeded.updateValueAndValidity();
      this.allocationFormGroup.controls.totalRemaining.setValue(fundingNeeded, { onlySelf: true, emitEvent: true });
      this.allocationFormGroup.controls.totalRemaining.updateValueAndValidity();
      this.allocationFormGroup.controls.useOfProceeds.controls.forEach(element => {
        element.controls["amount"].setValue("", { onlySelf: true, emitEvent: true });
        element.controls["amount"].updateValueAndValidity();
      });
      this.allocationFormGroup.controls.allocations.controls.forEach(element => {
        element.controls["amount"].setValue("", { onlySelf: true, emitEvent: false });
        element.controls["amount"].updateValueAndValidity();
        element.controls["checked"].setValue(false, { onlySelf: true, emitEvent: false });
        element.controls["checked"].updateValueAndValidity();
      });
    });
    this.allocationFormGroup.controls.useOfProceeds.valueChanges.subscribe(useOfProceed => {
      const subTotal = this.allocationFormGroup.controls.fundingNeeded.value || 0;
      const sum = useOfProceed.reduce((acc, item) => {
        return (acc + (+item.amount || 0));
      }, 0);
      this.allocationFormGroup.controls.totalRemaining.setValue(subTotal - sum, { onlySelf: true, emitEvent: false });
    });
  }

  back() {
    this._stepper.previous();
    this.location.go("bolt/owners", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  next() {
    if (this.allocationFormGroup.valid) {
      this._stepper.next();
      this.location.go("bolt/documents", `broker=${this.route.snapshot.queryParams['broker']}`);
    }
    else {
      this.allocationFormGroup.markAllAsTouched();
    }
  }

  getUseOfProceeds(): FormGroup[] {
    const agent = this.applicationService.getAgentInfo();
    const formGroup: FormGroup[] = [];
    agent.useOfProceeds.forEach((x: any) => {
      formGroup.push(this.newUseOfProceed(x.useOfProceedName));
    });
    return formGroup;
  }
 
  newUseOfProceed(purpose: string): FormGroup {
    return this._formBuilder.group({
      purpose: [purpose, [Validators.required]],
      amount: ['']
    }) as FormGroup;
  }

  getUseOfProceedItems(): FormGroup[] {
    const agent = this.applicationService.getAgentInfo();
    const formGroup: FormGroup[] = [];
    agent.useOfProceeds.forEach((x: any) => {
      x.useOfProceeds.forEach((y: any) => {
        formGroup.push(this.newUseOfProceedItem(x.useOfProceedName, y));
      });
    });
    return formGroup;
  }
 
  newUseOfProceedItem(useOfProceedName: string, purpose: string): FormGroup {
    return this._formBuilder.group({
      useOfProceedName: [useOfProceedName],
      purpose: [purpose],
      amount: [''],
      checked: false
    }) as FormGroup;
  }

  showUseOfProceeds() {
    return this.useOfProceeds.controls.some(item => item.get('purpose')?.value == 'Working Capital' && item.get('amount')?.value > '0');
  }
}

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const allocations = formGroup.get('allocations') as FormArray;
    const useOfProceeds = formGroup.get('useOfProceeds') as FormArray;
    const totalRemaining = formGroup.get('totalRemaining') as FormArray;
    if (useOfProceeds.controls.some(item => item.get('purpose')?.value == 'Working Capital' && item.get('amount')?.value > '0')) {
      let checked = 0;
      allocations.controls.forEach(key => {
        const control = key;
        if (control.value.checked === true) {
          checked++;
        }
      });
      if ((checked < minRequired)) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }
    }
    if (totalRemaining.value != 0) {
      return {
        totalRemainingInvalid: true,
      };
    }
    return null;
  };
}