import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QueryParamGuard } from './core/guards/query-param-quard/query-param-guard';
import { ErrorPageComponent } from './modules/error-page/error-page.component';
import { MainContentComponent } from './modules/main-content/main-content.component';
import { NoBrokerComponent } from './modules/no-broker/no-broker.component';

const routes: Routes = [
  {
    path: "bolt/intro",
    component: MainContentComponent,
    canActivate: [QueryParamGuard]
  },
  {
    path: "error",
    component: ErrorPageComponent
  },
  {
    path: "no-broker",
    component: NoBrokerComponent
  },
  {
    path: "**",
    redirectTo: "bolt/intro"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
