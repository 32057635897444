<div class="content-container flex flex-row justify-center">

    <div class="stepper-header flex flex-col justify-center">
        <div class="text-center">
            <h1 class="mat-h1">Error</h1>
        </div>
        <div class="content mat-body-1">
            <span>We are unable to process your request!</span>

        </div>
    </div>
</div>