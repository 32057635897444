import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollButton]'
})
export class ScrollButtonDirective {
  @Output() scrolledToBottom = new EventEmitter<boolean>();

  constructor(private el: ElementRef) { }

  @HostListener('scroll') onScroll() {
    const element = this.el.nativeElement as HTMLElement;
    const isBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    this.scrolledToBottom.emit(isBottom);
  }
}
