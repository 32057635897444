import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeSelectedValue'
})
export class ExcludeSelectedValuePipe implements PipeTransform {

  transform(fullArray: any[], allSelected: any[], currentSelection: string) {
    let newOptionsList: any = [];
    fullArray.forEach(function (model) {
      let addToArray = true;
      allSelected.forEach(function (key) {
        if (key.purpose === model.value) {
          addToArray = false;
        }
      });
      if (addToArray || model.value === currentSelection) {
        newOptionsList.push(model);
      }
    });
    return newOptionsList;
  }

}
