<div class="content-container flex flex-row justify-center" *ngIf="!hideMainContent">

    <div class="stepper-header flex flex-col justify-center">
        <div class="text-center">
            <h1 class="mat-h1">BayFirst Bolt Loan Application</h1>
        </div>
        <div class="content mat-body-1">
            <p>
                Thank you for your interest in applying for a small business loan with BayFirst National Bank. 
                Our Bolt loan is an SBA 7(a) term loan designed to provide lightning-fast funds for 
                small businesses that need it. We are excited to be working with you!
            </p>
            <p class="mt-2">
                Here are some details you should know:
            </p>
            <ol>
                <li>Certain industries, like <strong>transportation, advertising and marketing agencies, and restaurants</strong> are currently ineligible.</li>
                <li>Loan proceeds must be used for <strong>working capital or debt refinance only</strong>. Working capital examples include: marketing, hiring/payroll, other day-to-day operational expenses. Debt refinance examples include: business term loans and/or merchant capital advance loans.</li>
                <li>Minimum <strong>2 years in business</strong> required with weighted average FICO scores for <strong>all owners over 700.</strong></li>
                <li>This application must be completed in a single session. We will ask for:
                    <ul>
                        <li>Most recent two years of unredacted business tax returns.</li>
                        <li>Most recent unredacted personal tax return for every owner with 20% or more ownership in the business.</li>
                        <li>A valid driver’s license or other government issued identification for every owner with 20% or more ownership in the business.</li>
                    </ul>
                </li>
            </ol>

        </div>
        <div class="self-center">
            <button mat-flat-button (click)="nextBlock()" color="accent" class="action-button">Continue</button>
        </div>
    </div>
</div>
<app-stepper-form *ngIf="hideMainContent"></app-stepper-form>