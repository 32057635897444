import { createAction, props } from '@ngrx/store';
import { NAICSCodeInterface } from 'src/app/core/types/NAICSCodeInterface';

// Actions for  NAICS Code
export const getNAICSCode = createAction(
    '[BayFirst Component] Get NAICS Code',
    props<{ NAICSCode: any }>()
);
export const getNAICSCodeSuccess = createAction(
    '[BayFirst Component] Get NAICS Code success',
    props<{ NAICSCodes: NAICSCodeInterface[] }>()
);
export const getNAICSCodeFailure = createAction(
    '[BayFirst Component] Get NAICS Code failure',
    props<{ error: string }>()
);

// Actions for Broker Code
export const getBrokerCode = createAction(
    '[BayFirst Component] Broker Code',
    props<{ brokerCode: any }>()
);
export const getBrokerCodeSuccess = createAction(
    '[BayFirst Component] Broker Code success',
    props<{ payload: any }>()
);
export const getBrokerCodeFailure = createAction(
    '[BayFirst Component] Broker Code failure',
    props<{ error: string }>()
);

// Actions for Prequalification
export const prequalification = createAction(
    '[BayFirst Component] Prequalification',
    props<{ prequalification: any }>()
);
export const prequalificationSuccess = createAction(
    '[BayFirst Component] Prequalification success',
    props<{ payload: any }>()
);
export const prequalificationDeclined = createAction(
    '[BayFirst Component] Prequalification declined',
    props<{ message: string }>()
);
export const prequalificationFailure = createAction(
    '[BayFirst Component] Prequalification failure',
    props<{ error: string }>()
);

// Actions for Application
export const application = createAction(
    '[BayFirst Component] Application',
    props<{ application: any }>()
);
export const applicationSuccess = createAction(
    '[BayFirst Component] Application success',
    props<{ payload: any }>()
);
export const applicationDeclined = createAction(
    '[BayFirst Component] Application declined',
    props<{ message: string }>()
);
export const applicationFailure = createAction(
    '[BayFirst Component] Application failure',
    props<{ error: string }>()
);

// Actions for Document
export const documents = createAction(
    '[BayFirst Component] Document',
    props<{ documents: any }>()
);
export const documentsSuccess = createAction(
    '[BayFirst Component] Document success',
    props<{ payload: any }>()
);
export const documentsFailure = createAction(
    '[BayFirst Component] Document failure',
    props<{ error: string }>()
);