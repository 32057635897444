<div class="stepper-header flex flex-row justify-center">
    <div class="text-center">
        <h1 class="mat-h1 mb-0">General Information</h1>
        <p class="mat-subtitle-2 text-grey">* indicates a required field</p>
    </div>
</div>
<div class="flex flex-row justify-center grid grid-cols-12">
    <form [formGroup]="generalInformationFormGroup"
        class="flex flex-col xl:col-start-4 xl:col-span-6 lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
        <mat-label>Business name *</mat-label>
        <app-form-input-field [control]="$any(generalInformationFormGroup).get('businessName')" />

        <mat-label>Primary contact for the business *</mat-label>
        <div class="grid grid-cols-2 gap-2.5">
            <div class="flex flex-col">
                <app-form-input-field [control]="$any(generalInformationFormGroup).get('fname')"
                    placeholder="First Name" />
            </div>
            <div class="flex flex-col">
                <app-form-input-field [control]="$any(generalInformationFormGroup).get('lname')"
                    placeholder="Last Name" />
            </div>
        </div>

        <mat-label>Preferred phone number *</mat-label>
        <app-form-input-field [control]="$any(generalInformationFormGroup).get('phone')" mask="(000) 000-0000"
            [showMaskTyped]="true" />

        <mat-label>Email address *</mat-label>
        <app-form-input-field [control]="$any(generalInformationFormGroup).get('email')" />

        <mat-label>How much funding do you need? *</mat-label>
        <app-form-input-field [control]="$any(generalInformationFormGroup).get('fundingNeeded')" [isCurrency]="true"
            mask="separator.2">
            <span matTextPrefix>$&nbsp;</span>
            <span hint class="text-grey">Please enter an amount between $25,000 and $150,000</span>
        </app-form-input-field>

        <div class="flex flex-col items-center">
                <span class="mat-caption flex encriptionMessage text-grey gap-1">
                    <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                    <span>Your information is secure with 256-bit encription</span>
                </span>
                <button mat-flat-button (click)="next()" color="accent" class="action-button"
                    [disabled]="generalInformationFormGroup.invalid">Continue</button>
        </div>
    </form>
</div>