import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadDialog } from '../file-upload-dialog/file-upload-dialog.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  files: any[] = [];
  maxFileSize: number = 100 * 1024 * 1024;
  allowFileType: any[] = [
    "image/png",
    "image/jpeg",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];
  @Input('documentFor') documentFor: string;
  @Input('documentDescription') documentDescription: string;
  @Input('documentYearAndType') documentYearAndType: string;
  @Output('fileUploadHandler') fileUploadHandler = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {
  }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  async fileBrowseHandler($event: any) {
    await this.prepareFilesList($event.target.files);
  }

  async prepareFilesList(items: Array<any>) {
    if (items.length > 0) {
      for (const item of items){
        if (this.isFileValid(item)) {
          item.progress = 0;
          this.files.push(await this.readFile(item));
        }
      }
      if (this.files.length > 0) {
        this.fileUploadHandler.emit(this.files);
      }
    }
  }

  readFile(file: File) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve({ result: fr.result, name: file.name, progress: 100 })
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  isFileValid(file: any) {
    if (file) {
      if (file.size <= this.maxFileSize && this.allowFileType.includes(file.type)) {
        return true;
      }
    }
    this.dialog.open(FileUploadDialog, {
      width: '800px',
      disableClose: true,
      hasBackdrop: true
    });
    return false;
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileUploadHandler.emit(this.files);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    });
  }
}
