import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location, ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { heightTransition, horizontalStepTransition, overlayTransition } from 'src/app/core/animations/horizontal-step-transition';
import { ValidateAddressDialog } from 'src/app/core/components/validate-address/validate-address.component';
import { businessStructure, professionalTitle, stateOfIncorporation, states, suffix } from 'src/app/core/constants/dropdown-values';
import { checkPrimaryOwners, OwnersFormService, percentageSumValidator } from 'src/app/core/services/OwnersForm.service';
import { UspsService } from 'src/app/core/services/usps/usps.service';
import { ViewChildrenManagerService } from 'src/app/core/services/ViewChildrenManager.service';

@Component({
  selector: 'app-detailed-info-form',
  templateUrl: './detailed-info-form.component.html',
  styleUrls: ['./detailed-info-form.component.scss'],
  animations: [
    horizontalStepTransition('horizontalStepTransitionMain', '-100%'),
    horizontalStepTransition('horizontalStepTransitionChild', '100%'),
    overlayTransition('overlayTransition'),
    heightTransition()
  ]
})
export class DetailedInfoFormComponent implements OnInit {
  @Input() ownersFormGroup: FormGroup;
  @Output() toggleAdditionalInfo = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();
  @ViewChild("detailedStepper", { static: false }) private detailedStepper: MatStepper;
  @ViewChildren(DetailedInfoFormComponent) detailedInfoFormComponents!: QueryList<DetailedInfoFormComponent>;
  @Input() indexPath: number[] = [];

  suffix: any[] = suffix;
  states: any[] = states;
  today = new Date();
  dateOfBirthMaxDate: Date = new Date(
    this.today.getFullYear() - 18,
    this.today.getMonth(),
    this.today.getDate()
  );
  dateOfIncorporationMaxDate = this.today;
  addressValidationLoading: boolean = false;
  isAddressNotFound: boolean = false;
  stateOfIncorporation: any[] = stateOfIncorporation;
  businessStructure: any[] = businessStructure;
  professionalTitle: any[] = professionalTitle;
  showDetailedInfoForm: boolean = false;
  disableAnimations: boolean = false;

  constructor(
    public readonly _stepper: CdkStepper,
    private scroll: ViewportScroller,
    private location: Location,
    private route: ActivatedRoute,
    private uspsService: UspsService,
    public dialog: MatDialog,
    private scroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
    private viewChildrenManager: ViewChildrenManagerService,
    private ownersFormService: OwnersFormService
  ) {
  }

  get owners(): FormArray {
    return this.ownersFormGroup.get('owners') as FormArray;
  }

  ngOnInit(): void {
    this.setCurrentFormValidators();
  }

  setCurrentFormValidators(): void {
    this.owners.controls.map(owner => {
      (owner as FormGroup).get('mname')?.setValidators([]);
      (owner as FormGroup).get('suffix')?.setValidators([]);
      switch ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value) {
        case 'individual':
          (owner as FormGroup).get('fname')?.setValidators([Validators.required]);
          (owner as FormGroup).get('lname')?.setValidators([Validators.required]);
          (owner as FormGroup).get('title')?.setValidators([Validators.required]);
          (owner as FormGroup).get('isCitizenOrLegalPermanentRecident')?.setValidators([Validators.required]);
          (owner as FormGroup).get('dateOfBirth')?.setValidators([Validators.required]);
          (owner as FormGroup).get('ssn')?.setValidators([Validators.required]);
          (owner as FormGroup).get('phone')?.setValidators([Validators.required]);
          break;
        case 'business':
          (owner as FormGroup).get('legalBusinessName')?.setValidators([Validators.required]);
          (owner as FormGroup).get('businessStructure')?.setValidators([Validators.required]);
          (owner as FormGroup).get('employerIdentificationNumber')?.setValidators([Validators.required]);
          (owner as FormGroup).get('DBAOrTradeName')?.setValidators([]);
          (owner as FormGroup).get('dateOfIncorporation')?.setValidators([Validators.required]);
          (owner as FormGroup).get('stateOfIncorporation')?.setValidators([Validators.required]);
          (owner as FormGroup).addValidators([percentageSumValidator(),checkPrimaryOwners()]);
          if (((owner as FormGroup).get('owners') as FormArray).length > 0) {
            this.setValidators(((owner as FormGroup).get('owners') as FormArray).controls);
          }
          break;
        default:
          break;
      }
      (owner as FormGroup).get('ownershipPercentage')?.setValidators([Validators.required]);
      (owner as FormGroup).get('email')?.setValidators([Validators.required, Validators.email, RxwebValidators.email()]);
      (owner as FormGroup).get('streetAddress')?.setValidators([Validators.required]);
      (owner as FormGroup).get('suiteOrAPTName')?.setValidators([]);
      (owner as FormGroup).get('city')?.setValidators([Validators.required]);
      (owner as FormGroup).get('state')?.setValidators([Validators.required]);
      (owner as FormGroup).get('zip')?.setValidators([Validators.required, Validators.pattern(/(^\d{5}$)/)]);
      (owner as FormGroup).updateValueAndValidity();
    });
  }

  setValidators(controls: AbstractControl<any, any>[]) {
    controls.map(owner => {
      switch ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value) {
        case 'individual':
          (owner as FormGroup).get('fname')?.setValidators([Validators.required]);
          (owner as FormGroup).get('lname')?.setValidators([Validators.required]);
          break;
        case 'business':
          (owner as FormGroup).get('legalBusinessName')?.setValidators([Validators.required]);
          break;
      }
      (owner as FormGroup).get('ownershipPercentage')?.setValidators([Validators.required]);
      (owner as FormGroup).get('suffix')?.clearValidators();
      (owner as FormGroup).get('title')?.clearValidators();
      (owner as FormGroup).get('isCitizenOrLegalPermanentRecident')?.clearValidators();
      (owner as FormGroup).get('dateOfBirth')?.clearValidators();
      (owner as FormGroup).get('ssn')?.clearValidators();
      (owner as FormGroup).get('phone')?.clearValidators();
      (owner as FormGroup).get('email')?.clearValidators();
      (owner as FormGroup).get('streetAddress')?.clearValidators();
      (owner as FormGroup).get('suiteOrAPTName')?.clearValidators();
      (owner as FormGroup).get('city')?.clearValidators();
      (owner as FormGroup).get('state')?.clearValidators();
      (owner as FormGroup).get('zip')?.clearValidators();
      (owner as FormGroup).get('businessStructure')?.clearValidators();
      (owner as FormGroup).get('employerIdentificationNumber')?.clearValidators();
      (owner as FormGroup).get('dateOfIncorporation')?.clearValidators();
      (owner as FormGroup).get('stateOfIncorporation')?.clearValidators();
      (owner as FormGroup).updateValueAndValidity();
    });
  }

  stepperNext(index: number) {
    if (this.owners.at(index).valid) {
      this.addressValidationLoading = true;
      this.isAddressNotFound = false;
      var address = {
        streetAddress: this.owners.at(index).get('streetAddress')?.value?.toUpperCase(),
        city: this.owners.at(index).get('city')?.value?.toUpperCase(),
        state: this.owners.at(index).get('state')?.value?.toUpperCase(),
        ZIPCode: this.owners.at(index).get('zip')?.value?.toUpperCase()
      };
      this.uspsService.address(address).subscribe({
        next: (res) => {
          const suggestedAddress = {
            streetAddress: res.address.streetAddress,
            city: res.address.city,
            state: res.address.state,
            ZIPCode: res.address.ZIPCode
          };
          if (address.streetAddress != suggestedAddress.streetAddress || address.city != suggestedAddress.city || address.state != suggestedAddress.state || address.ZIPCode != suggestedAddress.ZIPCode) {
            this.LoadAddressDialog(address, suggestedAddress, true, index);
          } else {
            this.addressValidationLoading = false;
            this.isAddressNotFound = false;
            this.processNextStep(index);
          }
        },
        error: (err) => {
          this.LoadAddressDialog(address, null, false, index);
        }
      });
    }
    else {
      this.owners.at(index).markAllAsTouched();
    }
  }

  stepperPrevious(i: number) {
    if (this.detailedStepper.selectedIndex > 0) {
      let instances = this.viewChildrenManager.getAllInstances();
      let instance: DetailedInfoFormComponent | null = instances[0];
      if (!this.next.observed) {
        instance.showDetailedInfoForm = true;
        instance.disableAnimations = true;
        this.cdr.detectChanges();
        const previousStepInstance = instance.detailedInfoFormComponents.toArray().at(i - 1)!;
        if (previousStepInstance.ownersFormGroup.value.isOwnerBusinessOrIndividual == 'business') {
          this.processPreviousStep(previousStepInstance);
          instance.disableAnimations = false;
          instance.cdr.detectChanges();
        }
        else {
          instance.showDetailedInfoForm = false;
          instance.disableAnimations = false;
        }
      }
      else {
        instance.disableAnimations = false;
      }

      if (i == 1 && !this.next.observed) {
        instance.showDetailedInfoForm = false;
      }

      this.detailedStepper.previous();
    }
    else if (this.detailedStepper.selectedIndex === 0) {
      this.toggleAdditionalInfo.emit();
    }
  }

  processPreviousStep(instance: DetailedInfoFormComponent) {
    const owners = instance!.owners;
    if (owners.length > 0) {
      owners.controls.forEach((owner, index) => {
        if (owner.value.isOwnerBusinessOrIndividual == 'individual') {
          instance!.detailedStepper.next();
        }
        else {
          instance!.showDetailedInfoForm = true;
          instance!.cdr.detectChanges();
          this.processPreviousStep(instance.detailedInfoFormComponents.toArray().at(index)!);
        }
      })
    }
  }

  processNextStep(index: number) {
    if (this.owners.at(index).get('isOwnerBusinessOrIndividual')?.value == 'business') {
      this.showDetailedInfoForm = !this.showDetailedInfoForm;
      this.ownersFormService.sortOwners((this.owners.at(index) as FormGroup), this.cdr);
      this.setCurrentFormValidators();
      this.scroll.scrollToPosition([0, 0]);
    }
    else if (this.owners.controls.length == (this.detailedStepper.selectedIndex + 1) && this.owners.valid) {
      if (this.next.observed == false) {
        this.goNext();
      }
      this.next.emit();
    } 
    else if (this.owners.at(index).valid) {
      this.detailedStepper.next();
      this.scroll.scrollToPosition([0, 0]);
    }
    else {
      this.owners.at(index).markAllAsTouched();
    }
  }

  stepChanged(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  LoadAddressDialog(address: any, suggestedAddress: any, hasMatch: boolean, index: number) {
    const dialogRef = this.dialog.open(ValidateAddressDialog, {
      data: { address: address, suggestedAddress: suggestedAddress, hasMatch: hasMatch },
      width: '800px',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(res => {
      this.addressValidationLoading = false;
      if (res) {
        this.owners.at(index).get('streetAddress')?.setValue(res.streetAddress);
        this.owners.at(index).get('city')?.setValue(res.city);
        this.owners.at(index).get('state')?.setValue(res.state);
        this.owners.at(index).get('zip')?.setValue(res.ZIPCode);
        this.isAddressNotFound = false;
        this.processNextStep(index);
      }
      else {
        this.isAddressNotFound = true;
        this.scroller.scrollToAnchor('addressBlock');
      }
    });
  }

  nextStep() {
    if (this.owners.controls.length == (this.detailedStepper.selectedIndex + 1) && this.owners.valid) {
      if (this.owners.valid && this.ownersFormService.getOwnersFormGroup().valid && this.next.observed == false) {
        this.scroll.scrollToPosition([0, 0]);
        this.goNext();
      }
      else {
        this.next.emit();
      }
    }
    else {
      this.showDetailedInfoForm = !this.showDetailedInfoForm;
      this.detailedStepper.next();
      this.scroll.scrollToPosition([0, 0]);
    }
  }

  getItemGroup(index: number): FormGroup {
    return this.owners.at(index) as FormGroup;
  }

  handleDetailedInfoToggle() {
    this.showDetailedInfoForm = !this.showDetailedInfoForm;
    this.ownersFormService.handleDetailedInfoToggle(this.ownersFormGroup.get('owners') as FormArray);
    this.setCurrentFormValidators();
    this.cdr.detectChanges();    
  }

  getIndexPath(i: number): number[] {
    return [...this.indexPath, i];
  }

  goNext() {
    this._stepper.next();
    this.location.go("bolt/allocation", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  displayBusinessStructureRestricted(owner: any): boolean {
    if ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value == 'business') {
      const businessStructureSelected = this.businessStructure.filter(x => x.value == (owner as FormGroup).get('businessStructure')?.value)[0];
      let setControl = false;
      if ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value == 'business') {
        const businessStructureSelected = this.businessStructure.filter(x => x.value == (owner as FormGroup).get('businessStructure')?.value)[0];
        if (businessStructureSelected) {
          if (businessStructureSelected.isScheduleCRequired) {
            setControl = true;
          }
          if (!businessStructureSelected.displayScheduleC) {
            (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.setValue(businessStructureSelected.scheduleCValue, { onlySelf: true, emitEvent: true });
          }
        }
      }
      if (setControl) {
        (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.setValidators([Validators.required]);
      }
      else {
        (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.clearValidators();
      }
      (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.updateValueAndValidity();
      return businessStructureSelected?.displayScheduleC ?? false;
    }
    return false;
  }

  // maxOwnersAllowed(owner: any, index: number): number {
  //   if ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value == 'business') {
  //     const businessStructureSelected = this.businessStructure.filter(x => x.value == (owner as FormGroup).get('businessStructure')?.value)[0];
  //     if (businessStructureSelected) {
  //       if (businessStructureSelected.isScheduleCRequired && (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.value == 'yes') {
  //         const owners = this.getItemGroup(index);
  //         if (owners) {
  //           var ownerOwners = owners.get("owners") as FormArray;
  //           for (let i = 0; i < ownerOwners.controls.length; i++) {
  //             if (i > 0) {
  //               this.ownersFormService.removeOwner(owners, i);
  //             }
  //           }
  //           ownerOwners.push(this.ownersFormService.addOwner(owners, true, '100'));
  //           return 1;
  //         }
  //       }
  //     }
  //   }
  //   return 5;
  // }
}
