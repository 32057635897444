import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-form-autocomplete-field',
  templateUrl: './form-autocomplete-field.component.html',
  styleUrls: ['./form-autocomplete-field.component.scss']
})
export class FormAutocompleteFieldComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public options: any[];
  @Input() public placeholder: string;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  filteredOptions: any[];

  constructor(public formErrorService: FormErrorService) {
  }

  ngOnInit(): void {
    this.filteredOptions = this.options.slice();
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.options.filter(option => option.viewValue.toLowerCase().includes(filterValue));
  }

  displayFn = (value: any) => {
    if (this.options && this.options.length > 0) {
      const option = this.options.filter(item => item.value == value);
      if (option && option.length > 0) {
        return option[0].viewValue;
      }
    }
    return value;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control, 'select');
  }

  clearValue() {
    this.control.setValue('');
    this.control.updateValueAndValidity();
  }
}
