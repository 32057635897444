import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../services/application.service';
import { BayFirstService } from '../../services/bayfirst/bay-first.service';

@Injectable({
  providedIn: 'root'
})
export class QueryParamGuard implements CanActivate {
  constructor(private router: Router, private bayfirstService: BayFirstService, private applicationService: ApplicationService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const broker = route.queryParams['broker'];
    const productTypeName = "FlashCap BOLT";
    this.applicationService.setProductTypeName(productTypeName);
    if (broker && broker.length == 5) {
      return this.bayfirstService.broker(broker).pipe(map(res => {
        if (res.status == 'valid') {
          this.applicationService.setAgentInfo(res);
          return true;
        } else {
          this.router.navigateByUrl("no-broker");
          return false;
        }
      }));
    }
    else {
      this.router.navigateByUrl("bolt/intro?broker=" + environment.broker);
    }

    return false;
  }

}
