import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent {
  @Input('fileName') fileName: string;
  @Input('percentage') percentage: number;
  @Input('fileIndex') fileIndex: number;
  @Output('deleteFile') deleteFileFN = new EventEmitter<any>();

  constructor() {

  }

  deleteFile() {
    this.deleteFileFN.emit(this.fileIndex);
  }
}
