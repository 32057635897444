import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { Allocation, BusinessInformation, GeneralInformation } from 'src/app/core/constants/data-types';
import { OwnersFormService } from 'src/app/core/services/OwnersForm.service';
import { getApplicationBody, getPrequalificationBody } from 'src/app/core/utils/utils';
import { application, applicationSuccess, documents, documentsSuccess, prequalification, prequalificationSuccess } from 'src/app/store/actions/bayfirst.actions';
import { AppState } from 'src/app/store/selectors/bayfirst.selector';
import { AllocationComponent } from '../allocation/allocation.component';
import { BusinessInformationFormComponent } from '../business-information-form/business-information-form.component';
import { DocumentsComponent } from '../documents/documents.component';
import { GeneralInformationFormComponent } from '../general-information-form/general-information-form.component';
import { OwnerInformationFormComponent } from '../owner-information-form/owner-information-form.component';

@Component({
  selector: 'app-stepper-form',
  templateUrl: './stepper-form.component.html',
  styleUrls: ['./stepper-form.component.scss'],
})
export class StepperFormComponent implements OnInit {
  @ViewChild(GeneralInformationFormComponent, { static: true }) generalInformationFormComponent: GeneralInformationFormComponent;
  @ViewChild(BusinessInformationFormComponent, { static: true }) businessInformationFormComponent: BusinessInformationFormComponent;
  @ViewChild(OwnerInformationFormComponent, { static: true }) ownerInformationFormComponent: OwnerInformationFormComponent;
  @ViewChild(AllocationComponent, { static: true }) allocationComponent: AllocationComponent;
  @ViewChild(DocumentsComponent, { static: true }) documentsComponent: DocumentsComponent;

  generalInformationFormGroup: FormGroup;
  businessInformationFormGroup: FormGroup;
  ownerInformationFormGroup: FormGroup;
  allocationFormGroup: FormGroup;
  documentsFormGroup: FormGroup;
  labelPosition: Observable<any>;

  destroyed$ = new Subject<boolean>();
  brokerIdentifier: string;

  constructor(
    breakpointObserver: BreakpointObserver,
    private actions$: Actions,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private ownersFormService: OwnersFormService
  ) {
    this.labelPosition = breakpointObserver.observe('(min-width: 992px)').pipe(map(({ matches }) => (matches ? 'end' : 'bottom')));
  }

  ngOnInit(): void {
    this.generalInformationFormGroup = this.generalInformationFormComponent.generalInformationFormGroup;
    this.businessInformationFormGroup = this.businessInformationFormComponent.businessInformationFormGroup;
    this.ownerInformationFormGroup = this.ownerInformationFormComponent.ownersFormGroup;
    this.allocationFormGroup = this.allocationComponent.allocationFormGroup;
    this.documentsFormGroup = this.documentsComponent.documentsFormGroup;
    this.brokerIdentifier = this.route.snapshot.queryParams['broker'];
    this.actions$.pipe(
      ofType(prequalificationSuccess, applicationSuccess, documentsSuccess),
      takeUntil(this.destroyed$)
    ).subscribe((action: any) => {
      switch (action.type) {
        case prequalificationSuccess.type:
          const data = this.getFormData();
          const applicationBody = getApplicationBody(data, this.brokerIdentifier);
          applicationBody.preQualificationId = action.payload.preQualificationId;
          this.store.dispatch(application({ application: applicationBody }));
          break;
        case applicationSuccess.type:
          const applicationRes = action.payload;
          applicationRes.ownersReferenceId.forEach((item: any) => {
            this.documentsComponent.files.filter(fileData => !fileData.isBusinessDocument && fileData.taxIdNumber == item.taxIdNumber && fileData.isEntity == item.isEntity).map(mappedItem => {
              mappedItem.brokerIdentifier = this.brokerIdentifier,
              mappedItem.applicationReferenceId = applicationRes.applicationReferenceId,
              mappedItem.ownerReferenceId = mappedItem.ownershipPercentage < this.ownersFormService.ownershipPercentage ? applicationRes.applicationReferenceId : item.ownerReferenceId,
              mappedItem.documentType = mappedItem.ownershipPercentage < this.ownersFormService.ownershipPercentage ? "bank_internal_documents" : mappedItem.documentType,
              delete mappedItem.isBusinessDocument,
              delete mappedItem.taxIdNumber,
              delete mappedItem.isEntity,
              delete mappedItem.ownershipPercentage
            });
          });
          this.documentsComponent.files.filter(fileData => fileData.isBusinessDocument).map(mappedItem => {
            mappedItem.brokerIdentifier = this.brokerIdentifier,
            mappedItem.applicationReferenceId = applicationRes.applicationReferenceId,
            mappedItem.ownerReferenceId = applicationRes.applicationReferenceId,
            delete mappedItem.isBusinessDocument,
            delete mappedItem.taxIdNumber,
            delete mappedItem.isEntity,
            delete mappedItem.ownershipPercentage
          });
          this.documentsComponent.files.forEach(item => {
            this.store.dispatch(documents({ documents: item }));
          });
          break;
        case documentsSuccess.type:
          break;
      }
    });
  }

  formSubmit($event: any) {
    const data = this.getFormData();
    const prequalificationBody = getPrequalificationBody(data, this.brokerIdentifier);
    this.store.dispatch(prequalification({ prequalification: prequalificationBody }));
  }

  getFormData() {
    const formGroup = this.ownersFormService.getOwnersFormGroup();
    const data: {
      generalInformation: GeneralInformation,
      businessInformation: BusinessInformation,
      ownerInformation: FormArray,
      allocation: Allocation,
    } = {
      generalInformation: this.generalInformationFormGroup.value,
      businessInformation: this.businessInformationFormGroup.value,
      ownerInformation: this.ownersFormService.getOwners(formGroup).value,
      allocation: this.allocationFormGroup.value,
    }
    return data;
  }

  stepChanged(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
