import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class AppDialog {
    termsAndConditionsFormGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<AppDialog>,
        private formBuilder: FormBuilder
    ) {
        this.termsAndConditionsFormGroup = this.formBuilder.group({
            acceptTermsAndConditions: [false, [Validators.requiredTrue]]
        })
    }

    onSubmit() {
        if (this.termsAndConditionsFormGroup.get('acceptTermsAndConditions')?.value) {
            this.dialogRef.close(this.termsAndConditionsFormGroup.get('acceptTermsAndConditions')?.value);
        }
        else {
            this.termsAndConditionsFormGroup.markAllAsTouched();
        }
    }
}
