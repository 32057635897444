import * as dayjs from "dayjs"

export const getPrequalificationBody = (data: any, brokerIdentifier: string) => {
    return {
        "brokerIdentifier": brokerIdentifier,
        "useOfProceeds": getUseOfProceeds(data.allocation.useOfProceeds),
        "businessLegalName": data.businessInformation.legalBusinessName,
        "businessEmailAddress": data.generalInformation.email,
        "businessAddress": getAddress(data.businessInformation),
        "federalTaxId": data.businessInformation.employerIdentificationNumber,
        "businessPhoneNumber": data.generalInformation.phone,
        "contactFirstName": data.generalInformation.fname,
        "contactLastName": data.generalInformation.lname,
        "contactEmailAddress": data.generalInformation.email,
        "contactPhoneNumber": data.generalInformation.phone,
        "financials": {
            "cashAndEquivalents": null,
            "totalAssets": null,
            "totalLiabilities": null,
            "netWorth": null,
            "totalSales": null,
            "earningsBeforeInterestAndTaxes": null,
            "annualInterestExpense": null,
            "currentCheckingAccountBalance": null
        },
        "owners": getOwners(data.ownerInformation)
    }
}

export const getApplicationBody = (data: any, brokerIdentifier: string) => {
    return {
        "brokerIdentifier": brokerIdentifier,
        "preQualificationId": "",
        "productType": "FlashCap BOLT",
        "businessLegalName": data.businessInformation.legalBusinessName,
        "dba": data.businessInformation.doingBusinessAs,
        "contactFirstName": data.generalInformation.fname,
        "contactLastName": data.generalInformation.lname,
        "contactEmailAddress": data.generalInformation.email,
        "contactPhoneNumber": data.generalInformation.phone,
        "stateOfIncorporation": data.businessInformation.stateOfIncorporation,
        "useOfProceeds": getUseOfProceeds(data.allocation.useOfProceeds),
        "useOfProceedItems": [
            {
                "purpose": "Payroll/Hiring/Recruiting Expenses",
                "checked": data.allocation.allocation.payrollHiringRecruitingExpenses
            },
            {
                "purpose": "Marketing Expenses",
                "checked": data.allocation.allocation.marketingExpenses
            },
            {
                "purpose": "Supplies",
                "checked": data.allocation.allocation.supplies
            },
            {
                "purpose": "Accounts Payable",
                "checked": data.allocation.allocation.accountsPayable
            },
            {
                "purpose": "Operational Expenses",
                "checked": data.allocation.allocation.operationalExpenses
            }
        ],
        "legalStructure": data.businessInformation.businessStructure,
        "numberOfEmployees": String(data.businessInformation.numberOfEmployees),
        "dateOfEstablishment": dayjs(data.businessInformation.dateBusinessWasEstablished).format('YYYY-MM-DD'),
        "businessAddress": getAddress(data.businessInformation),
        "businessEmailAddress": data.generalInformation.email,
        "businessPhoneNumber": data.businessInformation.businessPhoneNo,
        "naicsCode": parseInt(data.businessInformation.NAICSCode),
        "isBusinessFranchise": data.businessInformation.isYourBusinessAFranchise == "yes",
        "federalTaxId": data.businessInformation.employerIdentificationNumber,
        "whatIsTheCombinedFairMarketValueOfTheBusinessAssets": +data.businessInformation.whatIsTheCombinedFairMarketValueOfTheBusinessAssets,
        "businessDescription": data.businessInformation.businessDescription,
        "isBusinessOwnAnyRealEstate": data.businessInformation.isBusinessOwnAnyRealEstate == "yes",
        "isBusinessPendingLitigation": data.businessInformation.isBusinessPendingLitigation == "yes",
        "isBusinessOutstandingJudgmentsOrTaxLiens": data.businessInformation.isBusinessOutstandingJudgmentsOrTaxLiens == "yes",
        "isBusinessReceiveSbaLoanLast90Days": data.businessInformation.isBusinessReceiveSbaLoanLast90Days == "yes",
        "owners": getOwners(data.ownerInformation)
    }
}

export const getAddress = (address: any) => {
    return {
        "address": address.streetAddress,
        "addressCont": address.suiteOrAPTName,
        "city": address.city,
        "state": address.state,
        "zip": address.zip
    };
}

export const getUseOfProceeds = (useOfProceeds: any) => {
    return useOfProceeds.filter((x: any) => x.amount > 0).map((item: any) => ({
        "purpose": item.purpose,
        "amount": String(item.amount),
        "other": ""
    }));
}

export const getOwners = (owners: any) => {
    return {
        "businesses": getBusinesses(owners),
        "persons": getPersons(owners)
    };
}

export const getBusinesses = (owners: any) => {
    if (owners && owners.length > 0) {
        return owners.filter((x: any) => x.isOwnerBusinessOrIndividual == "business").map((x: any) => ({
            "businessLegalName": x.legalBusinessName,
            "dba": x.legalBusinessName,
            "dateOfEstablishment": dayjs(x.dateOfIncorporation).format('YYYY-MM-DD'),
            "stateOfIncorporation": x.stateOfIncorporation,
            "address": getAddress(x),
            "federalTaxId": x.employerIdentificationNumber,
            "legalStructure": x.businessStructure,
            "phoneNumber": x.phone,
            "percentOwnership": parseFloat(x.ownershipPercentage),
            "emailAddress": x.email,
            "owners": getOwners(x.owners)
        }));
    }
    return [];
}

export const getPersons = (owners: any) => {
    if (owners && owners.length > 0) {
        return owners.filter((x: any) => x.isOwnerBusinessOrIndividual == "individual").map((x: any) => ({
            "firstName": x.fname,
            "middleName": x.mname,
            "lastName": x.lname,
            "suffixName": x.suffix,
            "ssn": x.ssn,
            "address": getAddress(x),
            "dateOfBirth": dayjs(x.dateOfBirth).format('YYYY-MM-DD'),
            "percentOwnership": parseFloat(x.ownershipPercentage),
            "monthlyIncome": null,
            "netWorth": null,
            "emailAddress": x.email,
            "phoneNumber": x.phone,
            "professionalTitle": x.title,
            "isUsCitizen": x.isCitizenOrLegalPermanentRecident == "yes",
            "isPrimaryOwner": x.isPrimaryOwner
        }));
    }
    return [];
}