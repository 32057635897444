export const environment = {
    name: "development",
    showToolbar: true,
    appBaseUrl: "",
    broker: "ABCDE",
    usps: {
        tokenURL: "https://api-dev.bayfirstfinancial.com/usps/oauth2/v3/token",
        addressURL: "https://api-dev.bayfirstfinancial.com/usps/addresses/v3/address",
        grant_type: "client_credentials",
        client_id: "ODvJ0FpjPxIdqbZRWRvNsO7Y63MuhQ8m",
        client_secret: "drkFCxK7lLh4lZaG"
    },
    bayfirst: {
        baseUrl: "https://api-dev.bayfirstfinancial.com:443/bfc/intake/experience/api/v1",
        headers: {
            "bfc-source-key": "postman-bfc-predefined-key",
            "bfc-source-name": "postman",
            "bfc-id-source-tran": "POSTMAN_BAYFIRST_TRANSACTION",
            "bfc-api-type": "process",
            "bfc-api-id": "12345-123124-123123",
            "client_id": "b4910428850f49b2a959824a5ce6e6c0",
            "client_secret": "5b38bed278794a3BA660658207866bC8",
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            'Access-Control-Allow-Origin': '*'
        }
    }
};
