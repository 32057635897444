<mat-form-field #materialFormField appearance="outline">
    <ng-container matTextPrefix>
        <ng-content select="[matTextPrefix]"></ng-content>
    </ng-container>
    <input matInput [formControl]="control" *ngIf="!mask && !isCurrency" [placeholder]="placeholder">
    <input matInput [formControl]="control" *ngIf="mask && isCurrency" [placeholder]="placeholder"
        thousandSeparator="," [mask]="mask" autocomplete="off" [allowNegativeNumbers]="allowNegativeNumbers">
    <input matInput [formControl]="control" [mask]="mask" [showMaskTyped]="showMaskTyped" *ngIf="mask && !isCurrency"
        [placeHolderCharacter]="placeHolderCharacter">
    <ng-container matSuffix>
        <ng-content select="[matSuffix]"></ng-content>
    </ng-container>
    <mat-hint align="start" class="mat-caption">
        <ng-content select="[hint]"></ng-content>
    </mat-hint>
    <mat-error *ngFor="let error of getErrors()">
        <mat-icon svgIcon="exclamation"></mat-icon>
        {{ error }}
    </mat-error>
</mat-form-field>