import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AddressData {
  streetAddress: string;
  city: string;
  state: string;
  ZIPCode: string;
}

export interface AddressesData {
  address: AddressData;
  suggestedAddress: AddressData;
  hasMatch: boolean;
}

@Component({
  selector: 'validate-address-dialog',
  templateUrl: './validate-address.component.html',
  styleUrls: ['./validate-address.component.scss']
})
export class ValidateAddressDialog {
  constructor(public dialogRef: MatDialogRef<ValidateAddressDialog>, @Inject(MAT_DIALOG_DATA) public addresses: AddressesData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
