<div class="stepper-header flex flex-row justify-center" *ngIf="!showDetailedInfoForm" [@horizontalStepTransitionMain]>
    <button mat-button (click)="back()" color="accent" class="stepper-previous">
        <mat-icon>keyboard_arrow_left</mat-icon> Back
    </button>
    <div class="text-center">
        <h1 class="mat-h1 mb-0">Owner Information</h1>
    </div>
</div>
<div class="flex flex-row justify-center grid grid-cols-12" *ngIf="!showDetailedInfoForm"
    [@horizontalStepTransitionMain]>
    <div class="flex flex-col xl:col-start-4 xl:col-span-6 lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
        <div>
            <span class="mat-body-1">
                <p>Enter the names of each business owner and their corresponding ownership percentages.</p>
                <p>A max of {{ maxOwnersAllowed }} owner(s) is permitted.</p>
                <p>The total ownership must be 100%.</p>
            </span>
        </div>
        <app-owners [ownersFormGroup]="ownersFormGroup" [disabledBusinessEntity]="disabledBusinessEntity" [maxOwnersAllowed]="maxOwnersAllowed" [ownershipPercentageLevelUp]="100"></app-owners>
        <div class="flex flex-col items-center">
            <span class="mat-caption flex encriptionMessage text-grey gap-1">
                <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                <span>Your information is secure with 256-bit encription</span>
            </span>
            <button mat-flat-button color="accent" class="action-button" (click)="addDetailedOwnerInfo()" [disabled]="!ownersFormGroup.valid">Continue</button>
        </div>
    </div>
</div>
<div *ngIf="showDetailedInfoForm" [@horizontalStepTransitionChild]>
    <app-detailed-info-form #detailedInfoForm [ownersFormGroup]="ownersFormGroup" (toggleAdditionalInfo)="handleDetailedInfoToggle()"></app-detailed-info-form>
</div>