<mat-form-field #materialFormField appearance="outline">
    <ng-container matTextPrefix>
        <ng-content select="[matTextPrefix]"></ng-content>
    </ng-container>
    <input matInput type="number" [formControl]="control" [placeholder]="placeholder">
    <ng-container matSuffix>
        <ng-content select="[matSuffix]"></ng-content>
    </ng-container>
    <mat-hint align="start" class="mat-caption">
        <ng-content select="[hint]"></ng-content>
    </mat-hint>
    <mat-error *ngFor="let error of getErrors()">
        <mat-icon svgIcon="exclamation"></mat-icon>
        {{ error }}
    </mat-error>
</mat-form-field>