import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class FormErrorService {

  constructor() { }

  getErrors(_formGroup: any, inputType?: string) {

    const errors = [];
    if (_formGroup && _formGroup?.errors && _formGroup.invalid && _formGroup.touched) {
      const formControlName = this.getControlName(_formGroup);

      for (const key of Object.keys((_formGroup as any).errors)) {

        switch (key) {
          case 'required':
            if (inputType == 'select') {
              errors.push('Please select an option');
            }
            else if (inputType == 'textarea') {
              errors.push('Please provide a description');
            }
            else if (inputType == 'radio') {
              errors.push('Please select an option');
            }
            else if (inputType == 'datepicker') {
              errors.push('Please select a date');
            }
            else {
              errors.push('Please enter a value');
            }
            break;
          case 'email':
            errors.push('Invalid email format');
            break;
          case 'minlength':
            errors.push(`Minimum length is ${(_formGroup as any)?.errors['minlength'].requiredLength}`);
            break;
          case 'min':
            errors.push(`${this.toCapitalizedWords(formControlName || '')} should be greater than ${(_formGroup as any)?.errors['min'].min}`);
            break;
          case 'max':
            errors.push(`${this.toCapitalizedWords(formControlName || '')} should be less than or equal to ${(_formGroup as any)?.errors['max'].max}`);
            break;
          case 'mask':
            errors.push(`Please enter a valid ${formControlName}`);
            break;
          case 'matDatepickerParse':
            break;
          case 'matDatepickerMax':
            const max = (_formGroup as any)?.errors['matDatepickerMax'].max;
            errors.push(`Max date should be ${dayjs(max).format('MM/DD/YYYY')}`);
            break;
          case 'matDatepickerMin':
            const min = (_formGroup as any)?.errors['matDatepickerMin'].min;
            errors.push(`Min date should be ${dayjs(min).format('MM/DD/YYYY')}`);
            break;
          case 'ownershipPercentageNotValid':
            errors.push('Ownership must account for 100% for all owners');
            break;
          // Add more error cases as needed
          default:
            errors.push('Invalid value');
            break;
        }
      }
    }
    return errors;
  }

  getControlName(c: AbstractControl) {
    const formGroup = c["_parent"].controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  toCapitalizedWords(name: string) {
    var words = name.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(this.capitalize).join(" ");
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }
}
