import { createReducer, on } from '@ngrx/store';
import { BayFirstStateInterface } from 'src/app/core/types/NAICSCodeStateInterface';
import { application, applicationDeclined, applicationFailure, applicationSuccess, documents, documentsFailure, documentsSuccess, getBrokerCode, getBrokerCodeFailure, getBrokerCodeSuccess, getNAICSCode, getNAICSCodeFailure, getNAICSCodeSuccess, prequalification, prequalificationDeclined, prequalificationFailure, prequalificationSuccess } from '../actions/bayfirst.actions';

export const initialState: BayFirstStateInterface = {
    isNAICSCodesLoading: false,
    NAICSCodes: [],
    NAICSCodesError: null,

    isBrokerCodeLoading: false,
    brokerCode: {},
    brokerCodeError: null,

    isPrequalificationLoading: false,
    prequalification: {},
    prequalificationDeclined: null,
    prequalificationError: null,

    isApplicationLoading: false,
    application: {},
    applicationDeclined: null,
    applicationError: null,

    isDocumentsLoading: false,
    documents: {},
    documentsError: null
};

export const BayFirstReducer = createReducer(
    initialState,
    // NAICS Code Reducer
    on(getNAICSCode, (state) => ({
        ...state,
        isNAICSCodesLoading: true
    })),
    on(getNAICSCodeSuccess, (state, action) => ({
        ...state,
        isNAICSCodesLoading: false,
        NAICSCodes: action.NAICSCodes,
    })),
    on(getNAICSCodeFailure, (state, action) => ({
        ...state,
        isNAICSCodesLoading: false,
        NAICSCodesError: action.error,
    })),
    // Broker Code Reducer
    on(getBrokerCode, (state) => ({
        ...state,
        isBrokerCodeLoading: true
    })),
    on(getBrokerCodeSuccess, (state, action) => ({
        ...state,
        isBrokerCodeLoading: false,
        brokerCode: action.payload,
    })),
    on(getBrokerCodeFailure, (state, action) => ({
        ...state,
        isBrokerCodeLoading: false,
        brokerCodeError: action.error,
    })),
    // prequalification Reducer
    on(prequalification, (state) => ({
        ...state,
        isPrequalificationLoading: true
    })),
    on(prequalificationSuccess, (state, action) => ({
        ...state,
        isPrequalificationLoading: false,
        prequalification: action.payload,
    })),
    on(prequalificationDeclined, (state, action) => ({
        ...state,
        isPrequalificationLoading: false,
        prequalificationDeclined: action.message,
    })),
    on(prequalificationFailure, (state, action) => ({
        ...state,
        isPrequalificationLoading: false,
        prequalificationError: action.error,
    })),
    // application Reducer
    on(application, (state) => ({
        ...state,
        isApplicationLoading: true
    })),
    on(applicationSuccess, (state, action) => ({
        ...state,
        isApplicationLoading: false,
        application: action.payload,
    })),
    on(applicationDeclined, (state, action) => ({
        ...state,
        isApplicationLoading: false,
        applicationDeclined: action.message,
    })),
    on(applicationFailure, (state, action) => ({
        ...state,
        isApplicationLoading: false,
        applicationError: action.error,
    })),
    // documents Reducer
    on(documents, (state) => ({
        ...state,
        isDocumentsLoading: true
    })),
    on(documentsSuccess, (state, action) => ({
        ...state,
        isDocumentsLoading: false,
        documents: action.payload,
    })),
    on(documentsFailure, (state, action) => ({
        ...state,
        isDocumentsLoading: false,
        documentsError: action.error,
    })),
);