import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';

@Injectable({
    providedIn: 'root'
})
export class OwnersFormService {
    ownersFormGroup: FormGroup;
    ownershipPercentage: number = 20;
    ids: string[] = [];

    constructor(private fb: FormBuilder) { }

    getOwnersFormGroup() {
        return this.ownersFormGroup;
    }

    createForm(): FormGroup {
        this.ownersFormGroup = this.fb.group({
            owners: this.fb.array([this.createOwner(true, "")])
        }, {
            validators: [percentageSumValidator(), checkPrimaryOwners()]
        });
        return this.ownersFormGroup;
    }

    createOwner(isPrimaryOwner: boolean, ownershipPercentage: string): FormGroup {
        return this.fb.group({
            uniqueId: [this.getUniqueId()],
            realOwnershipPercentage: [0],
            spouseUniqueId: [''],
            spouseRealOwnershipPercentage: [0],
            isPrimaryOwner: [isPrimaryOwner],
            isOwnerBusinessOrIndividual: ['individual', [Validators.required]],
            legalBusinessName: [''],
            doesYourBusinessFileTaxesOnAScheduleC: [''],
            fname: ['', [Validators.required]],
            lname: ['', [Validators.required]],
            businessStructure: [''],
            employerIdentificationNumber: [''],
            ownershipPercentage: [ownershipPercentage, Validators.compose([RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }), Validators.required, Validators.min(1), Validators.max(100)])],
            mname: ['', []],
            suffix: ['', []],
            title: ['', []],
            isCitizenOrLegalPermanentRecident: ['', []],
            dateOfBirth: ['', []],
            ssn: ['', []],
            phone: ['', []],
            email: ['', []],
            streetAddress: ['', []],
            suiteOrAPTName: ['', []],
            city: ['', []],
            state: ['', []],
            zip: ['', []],
            stateOfIncorporation: [''],
            DBAOrTradeName: [''],
            dateOfIncorporation: ['', []],
            owners: this.fb.array([])
        }) as FormGroup
    }

    getOwners(form: FormGroup): FormArray {
        return form.get('owners') as FormArray;
    }

    addOwner(form: FormGroup, isPrimaryOwner: boolean, ownershipPercentage: string): void {
        const owners = this.getOwners(form);
        owners.push(this.createOwner(isPrimaryOwner, ownershipPercentage));
    }

    removeOwner(form: FormGroup, index: number): void {
        const owners = this.getOwners(form);
        if (owners.length > 1) {
            owners.removeAt(index);
        }
    }

    handleDetailedInfoToggle(owners: FormArray) {
        owners.controls.map(owner => {
            (owner as FormGroup).get('fname')?.clearValidators();
            (owner as FormGroup).get('mname')?.clearValidators();
            (owner as FormGroup).get('lname')?.clearValidators();
            (owner as FormGroup).get('ownershipPercentage')?.clearValidators();
            (owner as FormGroup).get('suffix')?.clearValidators();
            (owner as FormGroup).get('title')?.clearValidators();
            (owner as FormGroup).get('isCitizenOrLegalPermanentRecident')?.clearValidators();
            (owner as FormGroup).get('dateOfBirth')?.clearValidators();
            (owner as FormGroup).get('ssn')?.clearValidators();
            (owner as FormGroup).get('phone')?.clearValidators();
            (owner as FormGroup).get('email')?.clearValidators();
            (owner as FormGroup).get('streetAddress')?.clearValidators();
            (owner as FormGroup).get('suiteOrAPTName')?.clearValidators();
            (owner as FormGroup).get('city')?.clearValidators();
            (owner as FormGroup).get('state')?.clearValidators();
            (owner as FormGroup).get('zip')?.clearValidators();
            (owner as FormGroup).get('businessStructure')?.clearValidators();
            (owner as FormGroup).get('employerIdentificationNumber')?.clearValidators();
            (owner as FormGroup).get('dateOfIncorporation')?.clearValidators();
            (owner as FormGroup).get('legalBusinessName')?.clearValidators();
            (owner as FormGroup).get('stateOfIncorporation')?.clearValidators();
            if (((owner as FormGroup).get('owners') as FormArray).length > 0) {
                (owner as FormGroup).clearValidators();
                this.handleDetailedInfoToggle(((owner as FormGroup).get('owners') as FormArray));
            }
            (owner as FormGroup).updateValueAndValidity();
        });
    }

    sortOwners(form: FormGroup, cdr?: ChangeDetectorRef) {
        const formArray = form.get('owners') as FormArray;
        const deepCopiedOwners = this.deepCopyFormArray(formArray);
        const sortedOwners = deepCopiedOwners.controls.sort((a, b) => b.value['isOwnerBusinessOrIndividual'].localeCompare(a.value['isOwnerBusinessOrIndividual']));
        sortedOwners.forEach(owner => {
            if (owner.get('isOwnerBusinessOrIndividual')?.value == 'business' && (owner.get('owners') as FormArray).length == 0) {
                (owner.get('owners') as FormArray).push(this.createOwner(false, ""));
            }
            else if (owner.get('isOwnerBusinessOrIndividual')?.value == 'individual') {
                if ((owner.get('owners') as FormArray).length > 0) {
                    (owner.get('owners') as FormArray).clear();
                }
            }
        });

        form.setControl('owners', deepCopiedOwners);
        if (cdr) {
            cdr.detectChanges();
        }
    }

    deepCopyFormGroup(formGroup: FormGroup): FormGroup {
        const newFormGroup = new FormGroup(
            Object.keys(formGroup.controls).reduce((acc, key) => {
                const control = formGroup.controls[key] as AbstractControl;
                if (control instanceof FormGroup) {
                    acc[key] = this.deepCopyFormGroup(control); // Recursively clone FormGroup
                } else if (control instanceof FormArray) {
                    acc[key] = this.deepCopyFormArray(control); // Recursively clone FormArray
                } else {
                    acc[key] = new FormControl(control.value); // Clone FormControl
                }
                return acc;
            }, {} as { [key: string]: AbstractControl })  // Explicitly define the accumulator type
        );
        return newFormGroup;
    }

    deepCopyFormArray(formArray: FormArray): FormArray {
        const newFormArray = new FormArray(
            formArray.controls.map(control => {
                if (control instanceof FormGroup) {
                    return this.deepCopyFormGroup(control); // Recursively clone FormGroup
                } else if (control instanceof FormArray) {
                    return this.deepCopyFormArray(control); // Recursively clone FormArray
                } else {
                    return new FormControl(control.value); // Clone FormControl
                }
            })
        );
        return newFormArray;
    }

    getUniqueId(): string {
        let isUnique = false;
        let tempId = '';
        while (!isUnique) {
            for (let index = 0; index < 5; index++) {
                tempId = tempId + (Math.floor(Math.random() * 9)).toString();
            }
            if (!this.ids.includes(tempId)) {
                isUnique = true;
                this.ids.push(tempId);
            }
        }
        return tempId;
    }

    getFullName(owner: AbstractControl<any, any>): string {
        const names = []
        if (owner.get('fname')?.value) {
          names.push(owner.get('fname')?.value.trim())
        }
        if (owner.get('mname')?.value) {
          names.push(owner.get('mname')?.value.trim())
        }
        if (owner.get('lname')?.value) {
          names.push(owner.get('lname')?.value.trim())
        }
        if (owner.get('suffix')?.value) {
          names.push(owner.get('suffix')?.value.trim())
        }
        return names.length > 0 ? names.join(' ').trim() : ''
    }

    calcRealOwnershipPercentage(ownershipPercentageLevel01: number, ownershipPercentageLevel02: number): number {
        return ((ownershipPercentageLevel01 / 100) * (ownershipPercentageLevel02 / 100)) * 100;
    }
}

export function percentageSumValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const formArray = formGroup.get('owners') as FormArray;
        if (!formArray) return null;
        // Calculate the sum of percentages
        const sum = formArray.controls.reduce((acc, control) => {
            return acc + (+control.get('ownershipPercentage')?.value || 0); // Ensure the value is a number
        }, 0);

        // Check if the sum is equal to 100
        if (sum !== 100) {
            // Return validation error if not equal
            return { percentageSumInvalid: true };
        }

        // Return null if the sum is equal to 100
        return null;
    };
}

export function checkPrimaryOwners(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const owners = formGroup.get('owners') as FormArray;
        if (!owners) {
            return null;
        }
        const individuals = owners.controls.filter(x => x.get('isOwnerBusinessOrIndividual')?.value == 'individual');
        if (individuals && individuals.length > 0) {
            const primaryOwners = individuals.filter(x => x.get('isPrimaryOwner')?.value == true);
            if (primaryOwners.length != 1) {
                return { primaryOwnersInvalid: true };
            }
        }
        return null;
    };
}