import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgentService } from './core/services/agent.service';
import { AgentInfo } from './core/types/agent-info';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  environment = environment;
  loading$: Observable<boolean> = of(false);
  agentInfo: AgentInfo = new AgentInfo({ id: 0});

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private agentService: AgentService
  ) {
    this.matIconRegistry.addSvgIcon("exclamation", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/exclamation.svg"));
    this.matIconRegistry.addSvgIcon("upload", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/upload.svg"));
    this.matIconRegistry.addSvgIcon("lock", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/lock.svg"));
    this.matIconRegistry.addSvgIcon("submit-error", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/error.svg"));
    this.matIconRegistry.addSvgIcon("submit-success", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/success.svg"));
    this.agentService.agentInfo$.subscribe(x => this.agentInfo = x);
  }

  ngOnInit() {
    this.loading$ = this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      map((e) => e instanceof NavigationStart)
    );
  }
}
