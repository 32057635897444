<mat-stepper *ngIf="!showOwnershipMapping" linear="true" #detailedStepper class="detailed-info" (selectionChange)="stepChanged($event);">
    <div [formGroup]="ownersFormGroup">
        <ng-container formArrayName="owners">
            <ng-container *ngFor="let owner of owners.controls; let i=index">
                <mat-step [stepControl]="owner">
                    <div class="stepper-header flex flex-row justify-center" *ngIf="!showDetailedInfoForm" [@horizontalStepTransitionMain]>
                        <button class="toggleDetailedInfo" mat-button (click)="stepperPrevious(i)" color="accent">
                            <mat-icon>keyboard_arrow_left</mat-icon> Back
                        </button>
                        <div class="text-center">
                            <ng-container [ngSwitch]="$any(owner).get('isOwnerBusinessOrIndividual').value">
                                <h1 class="mat-h1 mb-0" *ngSwitchCase="'individual'">
                                    {{ getFullName(owner) | titlecase }}
                                </h1>
                                <h1 class="mat-h1 mb-0" *ngSwitchCase="'business'">
                                    {{owner.get('legalBusinessName')?.value | titlecase }}
                                </h1>
                            </ng-container>
                            <p class="mat-h4">{{owner.get('ownershipPercentage')?.value}}% Owner</p>
                        </div>
                    </div>
                    <div class="flex flex-row justify-center grid grid-cols-12" *ngIf="!showDetailedInfoForm" [@horizontalStepTransitionMain]>
                        <div class="flex flex-col xl:col-start-4 xl:col-span-6 lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
                            <div [formGroupName]="i" class="flex flex-col">
                                <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'business'">
                                    <div class="sub-header">
                                        <h1 class="mat-h1 mb-0">
                                            Details of {{owner.get('legalBusinessName')?.value | titlecase }}
                                        </h1>
                                    </div>
                                    <mat-label>Business Legal Name *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('legalBusinessName')" />

                                    <mat-label>DBA or Trade Name</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('DBAOrTradeName')" />

                                    <mat-label>Date of incorporation *</mat-label>
                                    <app-form-date-picker-field [control]="$any(owner).get('dateOfIncorporation')" [maxDate]="dateOfIncorporationMaxDate" />

                                    <mat-label>State of Incorporation/Organization</mat-label>
                                    <app-form-select-field [control]="$any(owner).get('stateOfIncorporation')" [options]="stateOfIncorporation" placeholder="Please select" />

                                    <mat-label>Business structure *</mat-label>
                                    <app-form-select-field [control]="$any(owner).get('businessStructure')" [options]="businessStructure" placeholder="Please select" />

                                    <ng-container *ngIf="displayBusinessStructureRestricted(owner)">
                                        <mat-label>Does your business file taxes on a schedule C? *</mat-label>
                                        <app-form-radio-group [control]="$any(owner).get('doesYourBusinessFileTaxesOnAScheduleC')" />
                                    </ng-container>
                        
                                    <mat-label>Please enter the Employer Identification Number (EIN) *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('employerIdentificationNumber')" mask="XX-XXXXXXX" [showMaskTyped]="true" placeHolderCharacter="X" />

                                    <div class="sub-header">
                                        <h1 class="mat-h1 mb-0">
                                            Contact Information of {{owner.get('legalBusinessName')?.value | titlecase }}
                                        </h1>
                                    </div>

                                    <mat-label>Email *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('email')" />

                                    <mat-label>Business address *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('streetAddress')" />

                                    <mat-label>Suite or apt name (if applicable)</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('suiteOrAPTName')" />

                                    <mat-label>City *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('city')" />

                                    <div class="grid grid-cols-2 gap-2.5">
                                        <div class="flex flex-col">
                                            <mat-label>State *</mat-label>
                                            <app-form-select-field [control]="$any(owner).get('state')" [options]="states" placeholder="Please select" />
                                        </div>
                                        <div class="flex flex-col">
                                            <mat-label>Zip code *</mat-label>
                                            <app-form-input-field [control]="$any(owner).get('zip')" mask="00000" />
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'individual'">
                                    <div class="sub-header">
                                        <h1 class="mat-h1 mb-0">
                                            Details of {{owner.get('fname')?.value | titlecase }}
                                        </h1>
                                    </div>
                                    <div class="grid grid-cols-2 gap-2.5">
                                        <div class="flex flex-col">
                                            <mat-label>First Name *</mat-label>
                                            <app-form-input-field [control]="$any(owner).get('fname')" />
                                        </div>
                                        <div class="flex flex-col">
                                            <mat-label>Middle Name</mat-label>
                                            <app-form-input-field [control]="$any(owner).get('mname')" />
                                        </div>
                                    </div>

                                    <div class="grid grid-cols-2 gap-2.5">
                                        <div class="flex flex-col">
                                            <mat-label>Last Name *</mat-label>
                                            <app-form-input-field [control]="$any(owner).get('lname')" />
                                        </div>
                                        <div class="flex flex-col">
                                            <mat-label>Suffix</mat-label>
                                            <app-form-select-field [control]="$any(owner).get('suffix')" [options]="suffix" placeholder="Please select" />
                                        </div>
                                    </div>

                                    <mat-label>Professional title *</mat-label>
                                    <app-form-select-field [control]="$any(owner).get('title')" [options]="professionalTitle" placeholder="Please select" />

                                    <mat-label>Is this Owner/Principal a U.S. Citizen or Legal Permanent Resident? *</mat-label>
                                    <app-form-radio-group [control]="$any(owner).get('isCitizenOrLegalPermanentRecident')" />

                                    <mat-label>Date of birth *</mat-label>
                                    <app-form-date-picker-field [control]="$any(owner).get('dateOfBirth')" [maxDate]="dateOfBirthMaxDate" />

                                    <mat-label>Social security number *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('ssn')" mask="000-00-0000" placeHolderCharacter="_" [showMaskTyped]="true" />

                                    <div class="sub-header">
                                        <h1 class="mat-h1 mb-0">
                                            Contact Information of {{owner.get('fname')?.value | titlecase }}
                                        </h1>
                                    </div>

                                    <mat-label>Phone number *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('phone')" mask="(000) 000-0000" [showMaskTyped]="true" />

                                    <mat-label>Email *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('email')" />

                                    <mat-label>Home address *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('streetAddress')" />

                                    <mat-label>Suite or apt name (if applicable)</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('suiteOrAPTName')" />

                                    <mat-label>City *</mat-label>
                                    <app-form-input-field [control]="$any(owner).get('city')" />

                                    <div class="grid grid-cols-2 gap-2.5">
                                        <div class="flex flex-col">
                                            <mat-label>State *</mat-label>
                                            <app-form-select-field [control]="$any(owner).get('state')" [options]="states" placeholder="Please select" />
                                        </div>
                                        <div class="flex flex-col">
                                            <mat-label>Zip code *</mat-label>
                                            <app-form-input-field [control]="$any(owner).get('zip')" mask="00000" />
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'business'">
                                <div class="sub-header mb-0">
                                    <h1 class="mat-h1 mb-0">
                                        Business Owners
                                    </h1>
                                </div>
                                <div>
                                    <span class="mat-body-1">
                                        <p>Enter the names of each business owner and their corresponding ownership percentages.</p>
                                        <p>A max of 5 owner(s) is permitted.</p>
                                        <p>The total ownership must be 100%.</p>
                                    </span>
                                </div>
                                <app-owners [ownersFormGroup]="getItemGroup(i)" [disabledBusinessEntity]="true" [maxOwnersAllowed]="5" [ownershipPercentageLevelUp]="$any(owner).get('ownershipPercentage').value"></app-owners>
                            </ng-container>
                            <div class="flex flex-col items-center">
                                <span class="mat-caption flex encriptionMessage text-grey gap-1">
                                    <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                                    <span>Your information is secure with 256-bit encription</span>
                                </span>
                                <button mat-flat-button (click)="stepperNext(i)" color="accent" class="action-button" [disabled]="owner.invalid || addressValidationLoading">
                                    <mat-icon *ngIf="addressValidationLoading">
                                        <mat-spinner color="primary" diameter="16"></mat-spinner>
                                    </mat-icon>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showDetailedInfoForm" [@horizontalStepTransitionChild] [@.disabled]="disableAnimations">
                        <app-detailed-info-form #detailedInfoForm [ownersFormGroup]="getItemGroup(i)" (toggleAdditionalInfo)="handleDetailedInfoToggle()" (next)="nextStep()" [indexPath]="getIndexPath(i)"></app-detailed-info-form>
                    </div>
                </mat-step>
            </ng-container>
        </ng-container>
    </div>
</mat-stepper>
<div *ngIf="showOwnershipMapping">
    <div class="stepper-header flex flex-row justify-center">
        <button class="toggleDetailedInfo" mat-button (click)="closeOwnershipMapping()" color="accent">
            <mat-icon>keyboard_arrow_left</mat-icon> Back
        </button>
        <div class="text-center">
            <h1 class="mat-h1 mb-0">
                Ownership Information
            </h1>
        </div>
    </div>
    <div class="flex flex-row justify-center grid grid-cols-12">
        <div class="flex flex-col xl:col-start-4 xl:col-span-6 lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
            <div>
                <span class="mat-body-1">
                    <p>The SBA requires both spouses to personally guarantee a loan if their combined ownership in the borrowing business is {{ownershipPercentageMax}}% or more.</p>
                    <p>Click on the “Add Spouse” button to identify if the owner(s) listed are married to another owner of the business.</p>
                </span>
            </div>
            <mat-divider></mat-divider>
            <div class="text-left my-2">
                <div [formGroup]="ownersFormGroup">
                    <ng-container formArrayName="owners">
                        <ng-container *ngFor="let owner of owners.controls; let i=index">
                            <div [formGroupName]="i" class="flex flex-col">
                                <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'individual' && $any(owner).get('realOwnershipPercentage').value < ownershipPercentageMax">
                                    <div class="ownerInformationCard my-1-5">
                                        <div class="flex flex-row justify-between items-center">
                                            <div class="bold f-1-2 pt-0-5">
                                                <div>
                                                    {{ getFullName(owner) | titlecase }}
                                                </div>
                                                <ng-container *ngFor="let ownershipIndividual of ownershipIndividuals">
                                                    <ng-container *ngIf="ownershipIndividual.uniqueId == $any(owner).get('spouseUniqueId').value">
                                                        <div class="mt-0-5">
                                                            {{ownershipIndividual.fullName | titlecase}}
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <div *ngIf="$any(owner).get('spouseUniqueId').value != ''">
                                                    <button mat-flat-button color="primary" (click)="removeOwnerSpouse(i)">
                                                        <mat-icon fontIcon="delete"></mat-icon> Remove Spouse
                                                    </button>
                                                </div>
                                                <div *ngIf="$any(owner).get('spouseUniqueId').value == ''">
                                                    <button mat-flat-button color="primary" (click)="selectOwnerSpouse(i)" [disabled]="!isOwnershipIndividualsAvailable(i)">
                                                        <mat-icon fontIcon="add"></mat-icon> Add Spouse
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-col items-center">
                <span class="mat-caption flex encriptionMessage text-grey gap-1">
                    <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                    <span>Your information is secure with 256-bit encription</span>
                </span>
                <button mat-flat-button (click)="nextOwnershipMapping()" color="accent" class="action-button">
                    <mat-icon *ngIf="addressValidationLoading">
                        <mat-spinner color="primary" diameter="16"></mat-spinner>
                    </mat-icon>
                    Continue
                </button>
            </div>
        </div>
    </div>
</div>