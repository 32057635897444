<mat-form-field appearance="outline">
    <input type="text" placeholder='e.g. "restaurant" or 6-digit NAICS code' [formControl]="control" matInput
        [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions" [value]="option.code">
            {{option.title}} - {{option.code}}
        </mat-option>
    </mat-autocomplete>
    <ng-container matSuffix>
        <ng-container *ngIf="isFilteredOptionsLoading | async; then loading; else search">
        </ng-container>
    </ng-container>
    <mat-error *ngFor="let error of getErrors()">
        <mat-icon svgIcon="exclamation"></mat-icon>
        {{ error }}
    </mat-error>
    <mat-hint *ngIf="isNaicsCodeNotFound || (NAICSCodeError | async)">
        NAICS Code not found
    </mat-hint>
</mat-form-field>


<ng-template #loading>
    <mat-spinner class="search-spinner" diameter="20"></mat-spinner>
</ng-template>
<ng-template #search>
    <mat-icon>search</mat-icon>
</ng-template>