import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { BayFirstService } from 'src/app/core/services/bayfirst/bay-first.service';
import { application, applicationDeclined, applicationFailure, applicationSuccess, documents, documentsFailure, documentsSuccess, getBrokerCode, getBrokerCodeFailure, getBrokerCodeSuccess, getNAICSCode, getNAICSCodeFailure, getNAICSCodeSuccess, prequalification, prequalificationDeclined, prequalificationFailure, prequalificationSuccess } from '../actions/bayfirst.actions';

@Injectable()
export class BayFirstEffects {
    getNAICSCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getNAICSCode),
            mergeMap((payload) => {
                return this.bayfirstService.naicsCodes(payload.NAICSCode).pipe(
                    map((NAICSCodes) => {
                        return getNAICSCodeSuccess({ NAICSCodes })
                    }),
                    catchError((error) =>
                        of(getNAICSCodeFailure({ error: error.message }))
                    )
                );
            })
        )
    );

    getBrokerCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBrokerCode),
            mergeMap((payload) => {
                return this.bayfirstService.broker(payload.brokerCode).pipe(
                    map((res) => {
                        return getBrokerCodeSuccess({ payload: res })
                    }),
                    catchError((error) =>
                        of(getBrokerCodeFailure({ error: error.message }))
                    )
                );
            })
        )
    );

    postPrequalification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(prequalification),
            mergeMap((payload) => {
                return this.bayfirstService.prequalification(payload.prequalification).pipe(
                    map((res) => {
                        if (res.status == "Pre-approved" && res.qualifyingProducts.includes('FlashCap BOLT') && res.preQualificationId) {
                            return prequalificationSuccess({ payload: res });
                        }
                        const message = res.message ? res.message : "Something went wrong when submitting your application. Please reload the application and try again.";              
                        if (res.status == "Declined") {
                            return prequalificationDeclined({ message: message });
                        }
                        return prequalificationFailure({ error: message });
                    }),
                    catchError((error) =>
                        of(prequalificationFailure({ error: error.message }))
                    )
                );
            })
        )
    );

    postApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(application),
            mergeMap((payload) => {
                return this.bayfirstService.application(payload.application).pipe(
                    map((res) => {
                        if (res.status == "Submitted" && res.applicationReferenceId) {
                            return applicationSuccess({ payload: res })
                        }
                        const message = res.message ? res.message : "Something went wrong when submitting your application. Please reload the application and try again.";              
                        if (res.status == "Declined") {
                            return applicationDeclined({ message: message })
                        }
                        return applicationFailure({ error: message });
                    }),
                    catchError((error) =>
                        of(applicationFailure({ error: error.message }))
                    )
                );
            })
        )
    );

    postDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documents),
            mergeMap((payload) => {
                return this.bayfirstService.document(payload.documents).pipe(
                    map((res) => {
                        return documentsSuccess({ payload: res })
                    }),
                    catchError((error) =>
                        of(documentsFailure({ error: error.message }))
                    )
                );
            })
        )
    );

    constructor(private actions$: Actions, private bayfirstService: BayFirstService) { }
}