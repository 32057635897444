<h1 mat-dialog-title>File Validation</h1>
<div mat-dialog-content>
  <div>
    <div class="mat-body-1">
      Please upload a document that matches the below criteria.
    </div>
    <div class="mat-h3 primary italic my-0">
      File format: PDF, JPG, PNG, Word or Excel.
    </div>
    <div class="mat-h3 primary italic my-0">
      File max size: 100MB.
    </div>
  </div>
  <div class="text-right mt-3">
    <button mat-flat-button color="accent" (click)="onNoClick()">Close</button>          
  </div>
</div>
