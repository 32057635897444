<mat-stepper #stepper linear="true" [labelPosition]="labelPosition | async" (selectionChange)="stepChanged($event);">
    <mat-step [stepControl]="generalInformationFormGroup">
        <ng-template matStepLabel>General</ng-template>
        <app-general-information-form></app-general-information-form>
    </mat-step>
    <mat-step [stepControl]="businessInformationFormGroup">
        <ng-template matStepLabel>Business</ng-template>
        <app-business-information-form [generalInformationFormGroup]="generalInformationFormGroup"></app-business-information-form>
    </mat-step>
    <mat-step [stepControl]="ownerInformationFormGroup">
        <ng-template matStepLabel>Owners</ng-template>
        <app-owner-information-form [businessInformationFormGroup]="businessInformationFormGroup"></app-owner-information-form>
    </mat-step>
    <mat-step [stepControl]="allocationFormGroup">
        <ng-template matStepLabel>Allocation</ng-template>
        <app-allocation [generalInformationFormGroup]="generalInformationFormGroup"></app-allocation>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Documents</ng-template>
        <app-documents [businessInformationFormGroup]="businessInformationFormGroup" (formSubmit)="formSubmit($event)"></app-documents>
    </mat-step>
    <ng-template matStepperIcon="edit" let-index="index">
        {{index + 1}}
    </ng-template>
</mat-stepper>