import { Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatRadioChange } from '@angular/material/radio';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-form-radio-group',
  templateUrl: './form-radio-group.component.html',
  styleUrls: ['./form-radio-group.component.scss']
})
export class FormRaidoGroupComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() options: any[] = [
    { value: "yes", viewValue: "Yes" },
    { value: "no", viewValue: "No" }
  ];;
  @ContentChild(MatFormFieldControl, { static: true })
  public formFieldControl: MatFormFieldControl<any>;

  @ViewChild('materialFormField', { static: true })
  public matFormField: MatFormField;

  @Output('change') change = new EventEmitter<any>();

  constructor(public formErrorService: FormErrorService) {

  }

  public ngOnInit() {
    this.matFormField._control = this.formFieldControl;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control, 'select');
  }

  radioChange($event: MatRadioChange) {
    this.change.emit($event);
  }
}
