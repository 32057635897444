<mat-form-field appearance="outline">
    <input #input type="text" [placeholder]="placeholder" matInput [formControl]="control" [matAutocomplete]="auto" (input)="filter()" (focus)="filter()">
    <button *ngIf="control?.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue()">
        <mat-icon>close</mat-icon>
    </button>    
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [requireSelection]="true">
      <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
        {{option.viewValue}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngFor="let error of getErrors()">
        <mat-icon svgIcon="exclamation"></mat-icon>
        {{ error }}
    </mat-error>
  </mat-form-field>