import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OwnershipIndividual } from '../../types/ownership-individual';

@Component({
  selector: 'ownership-individual-dialog',
  templateUrl: './ownership-individual-dialog.component.html',
  styleUrls: ['./ownership-individual-dialog.component.scss']
})
export class OwnershipIndividualDialog {

  constructor(public dialogRef: MatDialogRef<OwnershipIndividualDialog>, @Inject(MAT_DIALOG_DATA) public data: { FullName: string, OwnershipIndividuals: OwnershipIndividual[] }
  ) {
  }

  onSelect(ownershipIndividual: OwnershipIndividual) {
    this.dialogRef.close(ownershipIndividual);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
