import { Component, ContentChild, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-form-input-number-field',
  templateUrl: './form-input-number-field.component.html',
  styleUrls: ['./form-input-number-field.component.scss']
})
export class FormInputNumberFieldComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public placeholder: string;
  @ContentChild(MatFormFieldControl, { static: true })

  public formFieldControl: MatFormFieldControl<any>;

  @ViewChild('materialFormField', { static: true })
  public matFormField: MatFormField;

  constructor(public formErrorService: FormErrorService) {
  }

  ngOnInit() {
    this.matFormField._control = this.formFieldControl;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control);
  }
}
