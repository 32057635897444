<mat-form-field #materialFormField appearance="outline">
    <mat-select [formControl]="control" [placeholder]="placeholder">
        <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.viewValue }}
        </mat-option>
    </mat-select>
    <mat-error *ngFor="let error of getErrors()">
        <mat-icon svgIcon="exclamation"></mat-icon>
        {{ error }}
    </mat-error>
</mat-form-field>