<div class="flex flex-row justify-between gap-2.5">
    <div><mat-icon>insert_drive_file</mat-icon></div>
    <div class="flex flex-col grid-cols-2 gap-2.5 grow">
        <div class="file-name flex flex-row justify-between">
            <div>{{fileName}}</div>
            <div [ngClass]="{'done': percentage == 100}" class="mat-caption">{{percentage}}%</div>
        </div>
        <mat-progress-bar mode="determinate" value="{{percentage}}"
            [color]="percentage < 100 ? 'accent' : 'primary'"></mat-progress-bar>
    </div>
    <div>
        <button mat-icon-button (click)="deleteFile()">
            <mat-icon>delete_outline</mat-icon>
        </button>
    </div>
</div>