import { Injectable, QueryList } from '@angular/core';
import { DetailedInfoFormComponent } from 'src/app/modules/owner-information-form/detailed-info-form/detailed-info-form.component';

@Injectable({
  providedIn: 'root'
})
export class ViewChildrenManagerService {
  private viewChildren: QueryList<DetailedInfoFormComponent> | null = null;

  setViewChildren(viewChildren: QueryList<DetailedInfoFormComponent>): void {
    this.viewChildren = viewChildren;
  }

  getViewChildren(): QueryList<DetailedInfoFormComponent> | null {
    return this.viewChildren;
  }

  getAllInstances(): DetailedInfoFormComponent[] {
    return this.viewChildren ? this.viewChildren.toArray() : [];
  }

  getInstanceByIndex(index: number): DetailedInfoFormComponent | null {
    return this.viewChildren && this.viewChildren.toArray()[index] ? this.viewChildren.toArray()[index] : null;
  }
}
