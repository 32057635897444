import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AgentInfo } from '../types/agent-info';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private agentInfoSubject = new Subject<AgentInfo>();;
  agentInfo$ = this.agentInfoSubject.asObservable();
  agent: AgentInfo;
  productTypeName: string;

  constructor() { }

  setAgentInfo(agent: any) {
    this.agent = new AgentInfo(agent, this.productTypeName);
    this.agentInfoSubject.next(this.agent);
  }

  getAgentInfo() {
    return this.agent;
  }

  setProductTypeName(productTypeName: string) {
    this.productTypeName = productTypeName;
  }

  getProductTypeName() {
    return this.productTypeName;
  }
}