import { createSelector } from '@ngrx/store';
import { BayFirstStateInterface } from 'src/app/core/types/NAICSCodeStateInterface';

export interface AppState {
    bayfirst: BayFirstStateInterface;
}

export const selectFeature = (state: AppState) => state.bayfirst;

export const isNAICSCodesLoading = createSelector(
    selectFeature,
    (state) => state.isNAICSCodesLoading
);

export const NAICSCodeSelector = createSelector(
    selectFeature,
    (state) => state.NAICSCodes
);

export const NAICSCodesError = createSelector(
    selectFeature,
    (state) => state.NAICSCodesError
);

export const isBrokerCodeLoading = createSelector(
    selectFeature,
    (state) => state.isBrokerCodeLoading
);

export const brokerCodeSelector = createSelector(
    selectFeature,
    (state) => state.brokerCode
);

export const brokerCodeError = createSelector(
    selectFeature,
    (state) => state.brokerCodeError
);

export const isPrequalificationLoading = createSelector(
    selectFeature,
    (state) => state.isPrequalificationLoading
);

export const prequalificationSelector = createSelector(
    selectFeature,
    (state) => state.prequalification
);

export const prequalificationError = createSelector(
    selectFeature,
    (state) => state.prequalificationError
);

export const isApplicationLoading = createSelector(
    selectFeature,
    (state) => state.isApplicationLoading
);

export const applicationSelector = createSelector(
    selectFeature,
    (state) => state.application
);

export const applicationError = createSelector(
    selectFeature,
    (state) => state.applicationError
);

export const isDocumentsLoading = createSelector(
    selectFeature,
    (state) => state.isDocumentsLoading
);

export const documentsSelector = createSelector(
    selectFeature,
    (state) => state.documents
);

export const documentsError = createSelector(
    selectFeature,
    (state) => state.documentsError
);