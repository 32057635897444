import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UspsService {
  private accessToken: string;
  private tokenRequested: boolean = false;

  constructor(public http: HttpClient) {
  }

  private token() {
    const body = {
      "grant_type": environment.usps.grant_type,
      "client_id": environment.usps.client_id,
      "client_secret": environment.usps.client_secret
    };
    return this.http.post<any>(environment.usps.tokenURL, body).pipe(
      map((response: any) => {
        if (response && response.access_token) {
          this.accessToken = response.access_token;
          return this.accessToken;
        } else {
          throw new Error('Access token not found in response');
        }
      }),
      catchError((error) => {
        throw new Error('Error fetching token: ' + error.message);
      })

    );
  }

  public address(params: any): Observable<any> {
    if (!this.accessToken && !this.tokenRequested) {
      this.tokenRequested = true;
      return this.token().pipe(
        catchError(() => {
          this.tokenRequested = false;
          return of(null);
        }),
        switchMap(() => {
          return this.http.get<any>(environment.usps.addressURL, {
            headers: {
              "Authorization": `Bearer ${this.accessToken}`,
              'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            params
          });
        })
      );

    }
    else {
      return this.http.get<any>(environment.usps.addressURL, {
        headers: {
          "Authorization": `Bearer ${this.accessToken}`,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        params
      });
    }
  }

}
