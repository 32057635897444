import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { heightTransition, horizontalStepTransition, overlayTransition } from 'src/app/core/animations/horizontal-step-transition';
import { suffix } from 'src/app/core/constants/dropdown-values';
import { OwnersFormService } from 'src/app/core/services/OwnersForm.service';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss'],
  animations: [
    horizontalStepTransition('horizontalStepTransitionMain', '-100%'),
    horizontalStepTransition('horizontalStepTransitionChild', '100%'),
    overlayTransition('overlayTransition'),
    heightTransition()
  ]
})
export class OwnersComponent {
  @Input() ownersFormGroup: FormGroup;
  @Input() disabledBusinessEntity: boolean;
  @Input() maxOwnersAllowed: number;

  showRemoveOwnerConfirmationIndex: number | undefined;
  suffix: any[] = suffix;
  isOwnerBusinessOrIndividual: any[] = [
    { value: "business", viewValue: "Business" },
    { value: "individual", viewValue: "Individual" }
  ]

  constructor(
    private ownersFormService: OwnersFormService
  ) { }

  get owners(): FormArray {
    return this.ownersFormGroup.get('owners') as FormArray;
  }

  addOwner() {
    this.ownersFormService.addOwner(this.ownersFormGroup, false, "");
  }

  removeOwner(ownerIndex: number) {
    this.ownersFormService.removeOwner(this.ownersFormGroup, ownerIndex);
    this.hideRemoveOwnerConfirmation();
  }

  showRemoveOwnerConfirmation(ownerIndex: number) {
    this.showRemoveOwnerConfirmationIndex = ownerIndex;
  }

  hideRemoveOwnerConfirmation() {
    this.showRemoveOwnerConfirmationIndex = undefined;
  }

  onisOwnerBusinessOrIndividualChange(i: number) {
    this.owners.controls[i].get('doesYourBusinessFileTaxesOnAScheduleC')?.clearValidators();
    this.owners.controls[i].get('doesYourBusinessFileTaxesOnAScheduleC')?.updateValueAndValidity();
    switch (this.owners.controls[i].get('isOwnerBusinessOrIndividual')?.value) {
      case 'individual':
        this.owners.controls[i].get('fname')?.setValidators([Validators.required]);
        this.owners.controls[i].get('lname')?.setValidators([Validators.required]);
        this.owners.controls[i].get('legalBusinessName')?.clearValidators();
        this.owners.controls[i].get('legalBusinessName')?.updateValueAndValidity();
        this.owners.controls[i].get('fname')?.updateValueAndValidity();
        this.owners.controls[i].get('lname')?.updateValueAndValidity();
        break;
      case 'business':
        this.owners.controls[i].get('fname')?.clearValidators();
        this.owners.controls[i].get('lname')?.clearValidators();
        this.owners.controls[i].get('legalBusinessName')?.setValidators([Validators.required]);
        this.owners.controls[i].get('legalBusinessName')?.updateValueAndValidity();
        this.owners.controls[i].get('fname')?.updateValueAndValidity();
        this.owners.controls[i].get('lname')?.updateValueAndValidity();
        break;
      default:
        break;
    }
  }
}

