import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-general-information-form',
  templateUrl: './general-information-form.component.html',
  styleUrls: ['./general-information-form.component.scss']
})
export class GeneralInformationFormComponent {
  generalInformationFormGroup = this._formBuilder.group({
    businessName: ['', [Validators.required]],
    fname: ['', [Validators.required]],
    lname: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, RxwebValidators.email()]],
    fundingNeeded: ['', [Validators.required, Validators.min(25000), Validators.max(150000)]],
  });

  constructor(
    private _formBuilder: FormBuilder,
    private readonly _stepper: CdkStepper,
    private location: Location,
    private route: ActivatedRoute
  ) { }


  next() {
    if (this.generalInformationFormGroup.valid) {
      this._stepper.next();
      this.location.go("bolt/business", `broker=${this.route.snapshot.queryParams['broker']}`);
    }
    else {
      this.generalInformationFormGroup.markAllAsTouched();
    }
  }
}
