import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location, ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { heightTransition, horizontalStepTransition, overlayTransition } from 'src/app/core/animations/horizontal-step-transition';
import { OwnershipIndividualDialog } from 'src/app/core/components/ownership-individual-dialog/ownership-individual-dialog.component';
import { ValidateAddressDialog } from 'src/app/core/components/validate-address/validate-address.component';
import { businessStructure, professionalTitle, stateOfIncorporation, states, suffix } from 'src/app/core/constants/dropdown-values';
import { checkPrimaryOwners, OwnersFormService, percentageSumValidator } from 'src/app/core/services/OwnersForm.service';
import { UspsService } from 'src/app/core/services/usps/usps.service';
import { ViewChildrenManagerService } from 'src/app/core/services/ViewChildrenManager.service';
import { OwnershipIndividual } from 'src/app/core/types/ownership-individual';

@Component({
  selector: 'app-detailed-info-form',
  templateUrl: './detailed-info-form.component.html',
  styleUrls: ['./detailed-info-form.component.scss'],
  animations: [
    horizontalStepTransition('horizontalStepTransitionMain', '-100%'),
    horizontalStepTransition('horizontalStepTransitionChild', '100%'),
    overlayTransition('overlayTransition'),
    heightTransition()
  ]
})
export class DetailedInfoFormComponent implements OnInit {
  @Input() ownersFormGroup: FormGroup;
  @Output() toggleAdditionalInfo = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();
  @ViewChild("detailedStepper", { static: false }) private detailedStepper: MatStepper;
  @ViewChildren(DetailedInfoFormComponent) detailedInfoFormComponents!: QueryList<DetailedInfoFormComponent>;
  @Input() indexPath: number[] = [];

  suffix: any[] = suffix;
  states: any[] = states;
  today = new Date();
  dateOfBirthMaxDate: Date = new Date(
    this.today.getFullYear() - 18,
    this.today.getMonth(),
    this.today.getDate()
  );
  dateOfIncorporationMaxDate = this.today;
  addressValidationLoading: boolean = false;
  isAddressNotFound: boolean = false;
  stateOfIncorporation: any[] = stateOfIncorporation;
  businessStructure: any[] = businessStructure;
  professionalTitle: any[] = professionalTitle;
  showDetailedInfoForm: boolean = false;
  disableAnimations: boolean = false;
  showOwnershipMapping: boolean = false;
  ownershipPercentageMax: number;
  ownershipIndividuals: OwnershipIndividual[] = [];

  constructor(
    public readonly _stepper: CdkStepper,
    private scroll: ViewportScroller,
    private location: Location,
    private route: ActivatedRoute,
    private uspsService: UspsService,
    public dialog: MatDialog,
    private scroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
    private viewChildrenManager: ViewChildrenManagerService,
    private ownersFormService: OwnersFormService
  ) {
  }

  get owners(): FormArray {
    return this.ownersFormGroup.get('owners') as FormArray;
  }

  ngOnInit(): void {
    this.showOwnershipMapping = false;
    this.ownershipPercentageMax = this.ownersFormService.ownershipPercentage;
    this.setCurrentFormValidators();
  }

  setCurrentFormValidators(): void {
    this.owners.controls.map(owner => {
      (owner as FormGroup).get('mname')?.setValidators([]);
      (owner as FormGroup).get('suffix')?.setValidators([]);
      switch ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value) {
        case 'individual':
          (owner as FormGroup).get('fname')?.setValidators([Validators.required]);
          (owner as FormGroup).get('lname')?.setValidators([Validators.required]);
          (owner as FormGroup).get('title')?.setValidators([Validators.required]);
          (owner as FormGroup).get('isCitizenOrLegalPermanentRecident')?.setValidators([Validators.required]);
          (owner as FormGroup).get('dateOfBirth')?.setValidators([Validators.required]);
          (owner as FormGroup).get('ssn')?.setValidators([Validators.required]);
          (owner as FormGroup).get('phone')?.setValidators([Validators.required]);
          break;
        case 'business':
          (owner as FormGroup).get('legalBusinessName')?.setValidators([Validators.required]);
          (owner as FormGroup).get('businessStructure')?.setValidators([Validators.required]);
          (owner as FormGroup).get('employerIdentificationNumber')?.setValidators([Validators.required]);
          (owner as FormGroup).get('DBAOrTradeName')?.setValidators([]);
          (owner as FormGroup).get('dateOfIncorporation')?.setValidators([Validators.required]);
          (owner as FormGroup).get('stateOfIncorporation')?.setValidators([Validators.required]);
          (owner as FormGroup).addValidators([percentageSumValidator(),checkPrimaryOwners()]);
          if (((owner as FormGroup).get('owners') as FormArray).length > 0) {
            this.setValidators(((owner as FormGroup).get('owners') as FormArray).controls);
          }
          break;
        default:
          break;
      }
      (owner as FormGroup).get('ownershipPercentage')?.setValidators([Validators.required]);
      (owner as FormGroup).get('email')?.setValidators([Validators.required, Validators.email, RxwebValidators.email()]);
      (owner as FormGroup).get('streetAddress')?.setValidators([Validators.required]);
      (owner as FormGroup).get('suiteOrAPTName')?.setValidators([]);
      (owner as FormGroup).get('city')?.setValidators([Validators.required]);
      (owner as FormGroup).get('state')?.setValidators([Validators.required]);
      (owner as FormGroup).get('zip')?.setValidators([Validators.required, Validators.pattern(/(^\d{5}$)/)]);
      (owner as FormGroup).updateValueAndValidity();
    });
  }

  setValidators(controls: AbstractControl<any, any>[]) {
    controls.map(owner => {
      switch ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value) {
        case 'individual':
          (owner as FormGroup).get('fname')?.setValidators([Validators.required]);
          (owner as FormGroup).get('lname')?.setValidators([Validators.required]);
          break;
        case 'business':
          (owner as FormGroup).get('legalBusinessName')?.setValidators([Validators.required]);
          break;
      }
      (owner as FormGroup).get('ownershipPercentage')?.setValidators([Validators.required]);
      (owner as FormGroup).get('suffix')?.clearValidators();
      (owner as FormGroup).get('title')?.clearValidators();
      (owner as FormGroup).get('isCitizenOrLegalPermanentRecident')?.clearValidators();
      (owner as FormGroup).get('dateOfBirth')?.clearValidators();
      (owner as FormGroup).get('ssn')?.clearValidators();
      (owner as FormGroup).get('phone')?.clearValidators();
      (owner as FormGroup).get('email')?.clearValidators();
      (owner as FormGroup).get('streetAddress')?.clearValidators();
      (owner as FormGroup).get('suiteOrAPTName')?.clearValidators();
      (owner as FormGroup).get('city')?.clearValidators();
      (owner as FormGroup).get('state')?.clearValidators();
      (owner as FormGroup).get('zip')?.clearValidators();
      (owner as FormGroup).get('businessStructure')?.clearValidators();
      (owner as FormGroup).get('employerIdentificationNumber')?.clearValidators();
      (owner as FormGroup).get('dateOfIncorporation')?.clearValidators();
      (owner as FormGroup).get('stateOfIncorporation')?.clearValidators();
      (owner as FormGroup).updateValueAndValidity();
    });
  }

  stepperNext(index: number) {
    if (this.owners.at(index).valid) {
      this.addressValidationLoading = true;
      this.isAddressNotFound = false;
      var address = {
        streetAddress: this.owners.at(index).get('streetAddress')?.value?.toUpperCase(),
        city: this.owners.at(index).get('city')?.value?.toUpperCase(),
        state: this.owners.at(index).get('state')?.value?.toUpperCase(),
        ZIPCode: this.owners.at(index).get('zip')?.value?.toUpperCase()
      };
      this.uspsService.address(address).subscribe({
        next: (res) => {
          const suggestedAddress = {
            streetAddress: res.address.streetAddress,
            city: res.address.city,
            state: res.address.state,
            ZIPCode: res.address.ZIPCode
          };
          if (address.streetAddress != suggestedAddress.streetAddress || address.city != suggestedAddress.city || address.state != suggestedAddress.state || address.ZIPCode != suggestedAddress.ZIPCode) {
            this.LoadAddressDialog(address, suggestedAddress, true, index);
          } else {
            this.addressValidationLoading = false;
            this.isAddressNotFound = false;
            this.processNextStep(index);
          }
        },
        error: (err) => {
          this.LoadAddressDialog(address, null, false, index);
        }
      });
    }
    else {
      this.owners.at(index).markAllAsTouched();
    }
  }

  stepperPrevious(i: number) {
    this.showOwnershipMapping = false;
    if (this.detailedStepper.selectedIndex > 0) {
      let instances = this.viewChildrenManager.getAllInstances();
      let instance: DetailedInfoFormComponent | null = instances[0];
      if (!this.next.observed) {
        instance.showDetailedInfoForm = true;
        instance.disableAnimations = true;
        this.cdr.detectChanges();
        const previousStepInstance = instance.detailedInfoFormComponents.toArray().at(i - 1)!;
        if (previousStepInstance.ownersFormGroup.value.isOwnerBusinessOrIndividual == 'business') {
          this.processPreviousStep(previousStepInstance);
          instance.disableAnimations = false;
          instance.cdr.detectChanges();
        }
        else {
          instance.showDetailedInfoForm = false;
          instance.disableAnimations = false;
        }
      }
      else {
        instance.disableAnimations = false;
      }

      if (i == 1 && !this.next.observed) {
        instance.showDetailedInfoForm = false;
      }

      this.detailedStepper.previous();
    }
    else if (this.detailedStepper.selectedIndex === 0) {
      this.toggleAdditionalInfo.emit();
    }
  }

  processPreviousStep(instance: DetailedInfoFormComponent) {
    const owners = instance!.owners;
    if (owners.length > 0) {
      owners.controls.forEach((owner, index) => {
        if (owner.value.isOwnerBusinessOrIndividual == 'individual') {
          instance!.detailedStepper.next();
        }
        else {
          instance!.showDetailedInfoForm = true;
          instance!.cdr.detectChanges();
          this.processPreviousStep(instance.detailedInfoFormComponents.toArray().at(index)!);
        }
      })
    }
  }

  processNextStep(index: number) {
    if (this.owners.at(index).get('isOwnerBusinessOrIndividual')?.value == 'business') {
      this.showDetailedInfoForm = !this.showDetailedInfoForm;
      this.ownersFormService.sortOwners((this.owners.at(index) as FormGroup), this.cdr);
      this.setCurrentFormValidators();
      this.scroll.scrollToPosition([0, 0]);
    }
    else if (this.owners.controls.length == (this.detailedStepper.selectedIndex + 1) && this.owners.valid) {
      if (this.next.observed == false) {
        this.goNext();
      }
      this.next.emit();
    } 
    else if (this.owners.at(index).valid) {
      this.detailedStepper.next();
      this.scroll.scrollToPosition([0, 0]);
    }
    else {
      this.owners.at(index).markAllAsTouched();
    }
  }

  stepChanged(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  LoadAddressDialog(address: any, suggestedAddress: any, hasMatch: boolean, index: number) {
    const dialogRef = this.dialog.open(ValidateAddressDialog, {
      data: { address: address, suggestedAddress: suggestedAddress, hasMatch: hasMatch },
      width: '800px',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(res => {
      this.addressValidationLoading = false;
      if (res) {
        this.owners.at(index).get('streetAddress')?.setValue(res.streetAddress);
        this.owners.at(index).get('city')?.setValue(res.city);
        this.owners.at(index).get('state')?.setValue(res.state);
        this.owners.at(index).get('zip')?.setValue(res.ZIPCode);
        this.isAddressNotFound = false;
        this.processNextStep(index);
      }
      else {
        this.isAddressNotFound = true;
        this.scroller.scrollToAnchor('addressBlock');
      }
    });
  }

  nextStep() {
    if (this.owners.controls.length == (this.detailedStepper.selectedIndex + 1) && this.owners.valid) {
      if (this.owners.valid && this.ownersFormService.getOwnersFormGroup().valid && this.next.observed == false) {
        this.scroll.scrollToPosition([0, 0]);
        this.goNext();
      }
      else {
        this.next.emit();
      }
    }
    else {
      this.showDetailedInfoForm = !this.showDetailedInfoForm;
      this.detailedStepper.next();
      this.scroll.scrollToPosition([0, 0]);
    }
  }

  getItemGroup(index: number): FormGroup {
    return this.owners.at(index) as FormGroup;
  }

  handleDetailedInfoToggle() {
    this.showDetailedInfoForm = !this.showDetailedInfoForm;
    this.ownersFormService.handleDetailedInfoToggle(this.ownersFormGroup.get('owners') as FormArray);
    this.setCurrentFormValidators();
    this.cdr.detectChanges();    
  }

  getIndexPath(i: number): number[] {
    return [...this.indexPath, i];
  }

  getFullName(owner: AbstractControl<any, any>): string {
    return this.ownersFormService.getFullName(owner);
  }

  goNext() {
    let showMatching = false;
    this.ownershipIndividuals = [];
    this.owners.controls.forEach(x => {
      if (x.get('isOwnerBusinessOrIndividual')?.value === 'individual') {
        if (x.get('realOwnershipPercentage')?.value < this.ownershipPercentageMax) {
          showMatching = true;
        }
      }
      else {
        if ((x.get('owners') as FormArray).length > 0) {
          (x.get('owners') as FormArray).controls.forEach(y => {
            if (y.get('realOwnershipPercentage')?.value < this.ownershipPercentageMax) {
              showMatching = true;
            }
          });
        }
      }
    });
    if (showMatching) {
      this.setOwnershipIndividuals();
      this.showOwnershipMapping = true;
    }
    else {
      this.nextOwnershipMapping();
    }
  }

  displayBusinessStructureRestricted(owner: any): boolean {
    if ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value == 'business') {
      const businessStructureSelected = this.businessStructure.filter(x => x.value == (owner as FormGroup).get('businessStructure')?.value)[0];
      let setControl = false;
      if ((owner as FormGroup).get('isOwnerBusinessOrIndividual')?.value == 'business') {
        const businessStructureSelected = this.businessStructure.filter(x => x.value == (owner as FormGroup).get('businessStructure')?.value)[0];
        if (businessStructureSelected) {
          if (businessStructureSelected.isScheduleCRequired) {
            setControl = true;
          }
          if (!businessStructureSelected.displayScheduleC) {
            (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.setValue(businessStructureSelected.scheduleCValue, { onlySelf: true, emitEvent: true });
          }
        }
      }
      if (setControl) {
        (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.setValidators([Validators.required]);
      }
      else {
        (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.clearValidators();
      }
      (owner as FormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.updateValueAndValidity();
      return businessStructureSelected?.displayScheduleC ?? false;
    }
    return false;
  }

  setOwnershipIndividuals() {
    this.owners.controls.forEach(x => {
      if (x.get('isOwnerBusinessOrIndividual')?.value === 'individual') {
        this.ownershipIndividuals.push(this.initIndividual(x));
      }
      else {
        if ((x.get('owners') as FormArray).length > 0) {
          (x.get('owners') as FormArray).controls.forEach(y => {
            this.ownershipIndividuals.push(this.initIndividual(y));
          });
        }
      }
    });
  }

  initIndividual(owner: AbstractControl<any, any>): OwnershipIndividual {
    return {
      uniqueId: owner.get('uniqueId')?.value,
      fullName: this.getFullName(owner),
      realOwnershipPercentage: owner.get('realOwnershipPercentage')?.value,
      hasBeenSelected: false
    };
  }

  nextOwnershipMapping() {
    this.owners.controls.map(x => {
      if (x.get('isOwnerBusinessOrIndividual')?.value === 'individual') {
        this.setSpouseOwnership(x);
      }
      else {
        if ((x.get('owners') as FormArray).length > 0) {
          (x.get('owners') as FormArray).controls.forEach(y => {
            this.setSpouseOwnership(y);
          });
        }
      }
    });
    this.showOwnershipMapping = false;
    this._stepper.next();
    this.location.go("bolt/allocation", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  setSpouseOwnership(x: AbstractControl<any, any>) {
    let spouseRealOwnershipPercentage = 0;
    const spouseUniqueId = x.get('spouseUniqueId')?.value;
    if (spouseUniqueId && spouseUniqueId != '') {
      if (this.ownershipIndividuals && this.ownershipIndividuals.length > 0) {
        this.ownershipIndividuals.forEach((w: any) => {
          if (w.uniqueId == spouseUniqueId) {
            spouseRealOwnershipPercentage = w.realOwnershipPercentage;
          }
        });
      }
    }
    x.get('spouseRealOwnershipPercentage')?.setValue(spouseRealOwnershipPercentage);
    x.get('spouseRealOwnershipPercentage')?.updateValueAndValidity();
  }

  removeOwnerSpouse(i: number) {
    this.ownershipIndividuals.forEach((w: any) => {
      if (w.uniqueId == this.owners.at(i).get('uniqueId')?.value) {
        w.hasBeenSelected = false;
      }
      if (w.uniqueId == this.owners.at(i).get('spouseUniqueId')?.value) {
        w.hasBeenSelected = false;
      }
    });
    this.owners.at(i).get('spouseUniqueId')?.setValue('');
    this.owners.at(i).get('spouseUniqueId')?.updateValueAndValidity();
    this.owners.at(i).get('spouseRealOwnershipPercentage')?.setValue(0);
    this.owners.at(i).get('spouseRealOwnershipPercentage')?.updateValueAndValidity();
    this.owners.controls.map(x => {
      if (x.get('isOwnerBusinessOrIndividual')?.value === 'individual') {
        if (x.get('spouseUniqueId')?.value == this.owners.at(i).get('uniqueId')?.value) {
          x.get('spouseUniqueId')?.setValue('');
          x.get('spouseUniqueId')?.updateValueAndValidity();
        }
      }
      else {
        if ((x.get('owners') as FormArray).length > 0) {
          (x.get('owners') as FormArray).controls.forEach(y => {
            if (y.get('spouseUniqueId')?.value == this.owners.at(i).get('uniqueId')?.value) {
              y.get('spouseUniqueId')?.setValue('');
              y.get('spouseUniqueId')?.updateValueAndValidity();
            }
          });
        }
      }
    });
  }

  selectOwnerSpouse(i: number) {
    const filteredOwnershipIndividuals = this.ownershipIndividuals.filter(x => x.hasBeenSelected == false && x.uniqueId != this.owners.at(i).get('uniqueId')?.value);
    const dialogRef = this.dialog.open(OwnershipIndividualDialog, {
      data: {
        FullName: this.getFullName(this.owners.at(i)),
        OwnershipIndividuals: filteredOwnershipIndividuals
      },
      width: '500px',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.owners.at(i).get('spouseUniqueId')?.setValue(result.uniqueId);
        this.owners.at(i).get('spouseUniqueId')?.updateValueAndValidity();
        this.ownershipIndividuals.forEach((w: any) => {
          if (w.uniqueId == result.uniqueId) {
            w.hasBeenSelected = true;
          }
          if (w.uniqueId == this.owners.at(i).get('uniqueId')?.value) {
            w.hasBeenSelected = true;
          }
        });
        this.owners.controls.map(x => {
          if (x.get('isOwnerBusinessOrIndividual')?.value === 'individual') {
            if (x.get('uniqueId')?.value == result.uniqueId) {
              x.get('spouseUniqueId')?.setValue(this.owners.at(i).get('uniqueId')?.value);
              x.get('spouseUniqueId')?.updateValueAndValidity();
            }
          }
          else {
            if ((x.get('owners') as FormArray).length > 0) {
              (x.get('owners') as FormArray).controls.forEach(y => {
                if (y.get('uniqueId')?.value == result.uniqueId) {
                  y.get('spouseUniqueId')?.setValue(this.owners.at(i).get('uniqueId')?.value);
                  y.get('spouseUniqueId')?.updateValueAndValidity();
                }
              });
            }
          }
        });
      }
    });
  }

  isOwnershipIndividualsAvailable(i: number): boolean {
    return this.ownershipIndividuals.filter(x => x.hasBeenSelected == false && x.uniqueId != this.owners.at(i).get('uniqueId')?.value)?.length > 0;
  }

  closeOwnershipMapping() {
    this.showOwnershipMapping = false;
  }
}