import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-form-input-field',
  templateUrl: './form-input-field.component.html',
  styleUrls: ['./form-input-field.component.scss']
})
export class FormInputFieldComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public placeholder: string;
  @Input() public mask: string;
  @Input() public showMaskTyped: boolean;
  @Input() public isCurrency: boolean = false;
  @Input() public placeHolderCharacter: string = "_";
  @Input() public allowNegativeNumbers: boolean = false;
  @ContentChild(MatFormFieldControl, { static: true })
  public formFieldControl: MatFormFieldControl<any>;

  @ViewChild('materialFormField', { static: true })
  public matFormField: MatFormField;

  constructor(public formErrorService: FormErrorService) {

  }

  ngOnInit() {
    this.matFormField._control = this.formFieldControl;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control);
  }
}
