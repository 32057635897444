import * as dayjs from "dayjs"

export const getPrequalificationBody = (data: any, brokerIdentifier: string, productTypeName: string) => {
    return {
        "brokerIdentifier": brokerIdentifier,
        "productType": productTypeName,
        "useOfProceeds": getUseOfProceeds(data.allocation),
        "businessLegalName": data.businessInformation.legalBusinessName,
        "businessEmailAddress": data.generalInformation.email,
        "businessAddress": getAddress(data.businessInformation),
        "federalTaxId": data.businessInformation.employerIdentificationNumber,
        "businessPhoneNumber": data.generalInformation.phone,
        "contactFirstName": data.generalInformation.fname,
        "contactLastName": data.generalInformation.lname,
        "contactEmailAddress": data.generalInformation.email,
        "contactPhoneNumber": data.generalInformation.phone,
        "financials": {
            "cashAndEquivalents": null,
            "totalAssets": null,
            "totalLiabilities": null,
            "netWorth": null,
            "totalSales": null,
            "earningsBeforeInterestAndTaxes": null,
            "annualInterestExpense": null,
            "currentCheckingAccountBalance": null
        },
        "owners": getOwners(data.ownerInformation)
    }
}

export const getApplicationBody = (data: any, brokerIdentifier: string, productTypeName: string) => {
    return {
        "brokerIdentifier": brokerIdentifier,
        "preQualificationId": "",
        "productType": productTypeName,
        "businessLegalName": data.businessInformation.legalBusinessName,
        "dba": data.businessInformation.doingBusinessAs,
        "contactFirstName": data.generalInformation.fname,
        "contactLastName": data.generalInformation.lname,
        "contactEmailAddress": data.generalInformation.email,
        "contactPhoneNumber": data.generalInformation.phone,
        "stateOfIncorporation": data.businessInformation.stateOfIncorporation,
        "useOfProceeds": getUseOfProceeds(data.allocation),
        "legalStructure": data.businessInformation.businessStructure,
        "numberOfEmployees": String(data.businessInformation.numberOfEmployees),
        "dateOfEstablishment": dayjs(data.businessInformation.dateBusinessWasEstablished).format('YYYY-MM-DD'),
        "businessAddress": getAddress(data.businessInformation),
        "businessEmailAddress": data.generalInformation.email,
        "businessPhoneNumber": data.businessInformation.businessPhoneNo,
        "naicsCode": parseInt(data.businessInformation.NAICSCode),
        "isBusinessFranchise": data.businessInformation.isYourBusinessAFranchise == "yes",
        "federalTaxId": data.businessInformation.employerIdentificationNumber,
        "whatIsTheCombinedFairMarketValueOfTheBusinessAssets": +data.businessInformation.whatIsTheCombinedFairMarketValueOfTheBusinessAssets,
        "businessDescription": data.businessInformation.businessDescription,
        "isBusinessOwnAnyRealEstate": data.businessInformation.isBusinessOwnAnyRealEstate == "yes",
        "isBusinessPendingLitigation": data.businessInformation.isBusinessPendingLitigation == "yes",
        "isBusinessOutstandingJudgmentsOrTaxLiens": data.businessInformation.isBusinessOutstandingJudgmentsOrTaxLiens == "yes",
        "isBusinessReceiveSbaLoanLast90Days": data.businessInformation.isBusinessReceiveSbaLoanLast90Days == "yes",
        "owners": getOwners(data.ownerInformation)
    }
}

export const getAddress = (address: any) => {
    return {
        "address": address.streetAddress,
        "addressCont": address.suiteOrAPTName,
        "city": address.city,
        "state": address.state,
        "zip": address.zip
    };
}

export const getUseOfProceeds = (allocation: any) => {
    return allocation.useOfProceeds.filter((x: any) => x.amount > 0).map((x: any) => ({
        "purpose": x.purpose,
        "amount": String(x.amount),
        "other": "",
        "useOfProceedItems": allocation.allocations.filter((y: any) => y.useOfProceedName == x.purpose).map((y: any) => ({
            "purpose": y.purpose,
            "amount": String(y.amount),
            "checked": y.checked
        }))
    }));
}

export const getOwners = (owners: any) => {
    return {
        "businesses": getBusinesses(owners),
        "persons": getPersons(owners)
    };
}

export const getBusinesses = (owners: any) => {
    if (owners && owners.length > 0) {
        return owners.filter((x: any) => x.isOwnerBusinessOrIndividual == "business").map((x: any) => ({
            "businessLegalName": x.legalBusinessName,
            "dba": x.legalBusinessName,
            "dateOfEstablishment": dayjs(x.dateOfIncorporation).format('YYYY-MM-DD'),
            "stateOfIncorporation": x.stateOfIncorporation,
            "address": getAddress(x),
            "federalTaxId": x.employerIdentificationNumber,
            "legalStructure": x.businessStructure,
            "phoneNumber": x.phone,
            "percentOwnership": parseFloat(x.ownershipPercentage),
            "emailAddress": x.email,
            "owners": getOwners(x.owners)
        }));
    }
    return [];
}

export const getPersons = (owners: any) => {
    if (owners && owners.length > 0) {
        return owners.filter((x: any) => x.isOwnerBusinessOrIndividual == "individual").map((x: any) => ({
            "firstName": x.fname,
            "middleName": x.mname,
            "lastName": x.lname,
            "suffixName": x.suffix,
            "ssn": x.ssn,
            "address": getAddress(x),
            "dateOfBirth": dayjs(x.dateOfBirth).format('YYYY-MM-DD'),
            "percentOwnership": parseFloat(x.ownershipPercentage),
            "monthlyIncome": null,
            "netWorth": null,
            "emailAddress": x.email,
            "phoneNumber": x.phone,
            "professionalTitle": x.title,
            "isUsCitizen": x.isCitizenOrLegalPermanentRecident == "yes",
            "isPrimaryOwner": x.isPrimaryOwner,
            "uniqueId": x.uniqueId,
            "spouseUniqueId": x.spouseUniqueId
        }));
    }
    return [];
}