import { Component, ContentChild, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-form-date-picker-field',
  templateUrl: './form-date-picker-field.component.html',
  styleUrls: ['./form-date-picker-field.component.scss']
})
export class FormDatePickerFieldComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public placeholder: string = 'MM/DD/YYYY';
  @Input() minDate: Date;
  @Input() maxDate: Date;


  @ContentChild(MatFormFieldControl, { static: true })
  public formFieldControl: MatFormFieldControl<any>;

  @ViewChild('materialFormField', { static: true })
  public matFormField: MatFormField;

  constructor(public formErrorService: FormErrorService) {
    
  }

  ngOnInit() {
    this.matFormField._control = this.formFieldControl;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control, 'datepicker');
  }
}