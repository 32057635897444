import { trigger, style, transition, animate, query, animateChild, sequence, group } from '@angular/animations';

export const horizontalStepTransition = (name: string, stepDirectionX: string) => {
    return trigger(name, [
        transition(':enter', [
            style({ transform: `translate3d(${stepDirectionX}, 0, 0)`, visibility: 'inherit' }),
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform: 'none', visibility: 'inherit' }))
        ]),
        transition(':leave', [
            style({ transform: 'none', visibility: 'hidden', display: 'none' }),
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform: `translate3d(${stepDirectionX}, 0, 0)`, visibility: 'hidden' })),
        ]),
    ])
}

export const overlayTransition = (name: string) => {
    return trigger(name, [
        transition(':enter', [
            group([
                style({ opacity: 0, transform: 'scale(.8)' }),
                animate('100ms', style({ transform: 'scale(1)' })),
                animate('100ms', style({ opacity: 1 })),
            ])
        ]),
        transition(':leave', [
            style({ opacity: 1, transform: 'scale(1)' }),
            animate('100ms', style({ opacity: 0, transform: 'scale(.8)' }))
        ])
    ])
}

export const heightTransition = (name: string = 'heightTransition') => {
    return trigger(name, [
        transition(':enter', [
            sequence([
                style({ opacity: 0, height: '0px', }),
                animate('100ms', style({ height: '*' })),
                animate('150ms', style({ opacity: 1 }))
            ])
        ]),
        transition(':leave', [
            sequence([
                style({ opacity: 1, height: '*' }),
                animate('100ms', style({ opacity: 0 })),
                animate('150ms', style({ height: '0px', }))
            ])
        ])
    ])
}

