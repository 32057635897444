import { Component, ContentChild, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';

@Component({
  selector: 'app-form-textarea-field',
  templateUrl: './form-textarea-field.component.html',
  styleUrls: ['./form-textarea-field.component.scss']
})
export class FormTextareaFieldComponent  implements OnInit {
  @Input() public control: FormControl;
  @ContentChild(MatFormFieldControl, { static: true })
  public formFieldControl: MatFormFieldControl<any>;

  @ViewChild('materialFormField', { static: true })
  public matFormField: MatFormField;

  constructor(public formErrorService: FormErrorService) {

  }

  ngOnInit() {
    this.matFormField._control = this.formFieldControl;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control,'textarea');
  }
}
