<div [formGroup]="ownersFormGroup">
    <ng-container formArrayName="owners">
        <ng-container *ngFor="let owner of owners.controls; let i=index">
            <div [formGroupName]="i" class="ownerInformationCard flex flex-col" [@heightTransition]>
                <div class="flex flex-row justify-between items-center">
                    <h1 class="mat-h2 mb-0">Owner Information</h1>
                    <button *ngIf="owners.controls.length > 1" mat-button class="delete-owner" (click)="showRemoveOwnerConfirmation(i)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
                <ng-container *ngIf="!disabledBusinessEntity">
                    <mat-label>Is this owner a business or individual? *</mat-label>
                    <app-form-radio-group [control]="$any(owner).get('isOwnerBusinessOrIndividual')" [options]="isOwnerBusinessOrIndividual" (change)="onisOwnerBusinessOrIndividualChange(i)" />
                </ng-container>
                <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'business'">
                    <mat-label>Business' Legal Name *</mat-label>
                    <app-form-input-field [control]="$any(owner).get('legalBusinessName')" />
                </ng-container>
                <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'individual'">
                    <div class="grid grid-cols-2 gap-2.5">
                        <div class="flex flex-col">
                            <mat-label>First Name *</mat-label>
                            <app-form-input-field [control]="$any(owner).get('fname')" />
                        </div>
                        <div class="flex flex-col">
                            <mat-label>Last Name *</mat-label>
                            <app-form-input-field [control]="$any(owner).get('lname')" />
                        </div>
                    </div>
                </ng-container>
                <mat-label>Ownership percentage *</mat-label>
                <app-form-input-field [control]="$any(owner).get('ownershipPercentage')">
                    <mat-icon matSuffix>percentage</mat-icon>
                </app-form-input-field>
                <ng-container *ngIf="$any(owner).get('isOwnerBusinessOrIndividual').value == 'individual' && maxOwnersAllowed > 1">
                    <mat-checkbox [formControl]="$any(owner).get('isPrimaryOwner')">
                        <mat-label>Is Primary Owner?</mat-label>
                    </mat-checkbox>
                </ng-container>
                <div class="overlay flex flex-col justify-center items-center"
                    *ngIf="showRemoveOwnerConfirmationIndex == i" [@overlayTransition]>
                    <div class="flex flex-col">
                        <h1>Remove this owner?</h1>
                        <div class="flex gap-2.5">
                            <button mat-flat-button class="action-button" (click)="hideRemoveOwnerConfirmation()">Cancel</button>
                            <button mat-flat-button color="warn" class="action-button" (click)="removeOwner(i)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<button mat-flat-button *ngIf="maxOwnersAllowed > 1" (click)="addOwner()" class="addOwner" [disabled]="owners.length >= maxOwnersAllowed">
    <mat-icon>add</mat-icon> Add another owner
</button>
<p class="mat-body-2 error-text" *ngIf="ownersFormGroup.touched && ownersFormGroup!.hasError('percentageSumInvalid')">
    You must account for 100% of the ownership to proceed.
</p>
<p class="mat-body-2 error-text" *ngIf="ownersFormGroup.touched && ownersFormGroup!.hasError('primaryOwnersInvalid')">
    You must select one owner as the primary owner.
</p>