import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent {
  hideMainContent: boolean = false;

  constructor(
    private location: Location,
    private route: ActivatedRoute
  ) { }

  nextBlock() {
    this.hideMainContent = true;
    this.location.replaceState("bolt/general", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

}
