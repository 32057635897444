import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { skip, tap, Observable } from 'rxjs';
import { FormErrorService } from '../../services/form-error-service/form-error-service.service';
import { AppState, NAICSCodeSelector, NAICSCodesError, isNAICSCodesLoading } from 'src/app/store/selectors/bayfirst.selector';

@Component({
  selector: 'app-naicscode',
  templateUrl: './naicscode.component.html',
  styleUrls: ['./naicscode.component.scss']
})
export class NAICSCodeComponent implements OnInit {
  filteredOptions: any[] = [];
  isFilteredOptionsLoading: Observable<boolean>;
  NAICSCodeError: Observable<any>;
  @Input('isNaicsCodeNotFound') isNaicsCodeNotFound: boolean = false;
  @Input() public control: FormControl;

  constructor(
    public store: Store<AppState>,
    public formErrorService: FormErrorService
  ) { }

  ngOnInit(): void {
    this.store.select(NAICSCodeSelector).pipe(
      skip(1),
      tap((res: any) => {
        this.isNaicsCodeNotFound = res.length == 0;
      })
    ).subscribe(options => {
      this.filteredOptions = options;
    });
    this.isFilteredOptionsLoading = this.store.select(isNAICSCodesLoading);
    this.NAICSCodeError = this.store.select(NAICSCodesError);

  }

  displayFn = (code: any) => {
    if (this.filteredOptions && this.filteredOptions.length > 0) {
      const NAICSCode = this.filteredOptions.filter(item => item.code == code);
      return NAICSCode[0].title + " - " + NAICSCode[0].code;
    }
    return code;
  }

  getErrors() {
    return this.formErrorService.getErrors(this.control);
  }
}
