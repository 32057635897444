<mat-radio-group #materialFormField [formControl]="control" (change)="radioChange($event)">
    <mat-radio-button *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}
    </mat-radio-button>
    <div class="error-message">
        <mat-error *ngFor="let error of getErrors()">
            <mat-icon svgIcon="exclamation"></mat-icon>
            {{ error }}
        </mat-error>
    </div>
</mat-radio-group>